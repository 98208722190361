.container {
  display: flex;
  align-items: center;
  /* column-gap: 10px; */
}

.needAuthorize {
  display: none;
}

.link,
.active {
  padding: 8px 20px;
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  text-transform: uppercase;
  font-feature-settings: 'pnum' on, 'lnum' on;
  color: var(--color-white);
  border-radius: 16px;
  transition: all 0.3s ease;
}

.link:hover {
  background-color: var(--color-light-purple);
  color: var(--color-text);
}

/* TODO need to check  */
.active {
  background-color: var(--color-light-purple);
  color: var(--color-text);
}

.scrolled {
  color: var(--color-text);
}

/* .fixed-header .main-nav .nav-link {
  color: var(--color-text);
} */


/* .main-nav .nav-link-fixed {
    padding: 8px 20px;
    font-weight: 700;
    font-size: 14px;
    text-transform: uppercase;
    color: #3C3C3B;
    margin-left: 20px;
    border-radius: 12px;
    transition: all 0.3s ease;
    } */

@media screen and (max-width: 768px) {
  .container {
    display: none;
  }

  .link {
    margin-bottom: 10px;
    font-size: 24px;
    line-height: 33px;
  }

  .container {
    display: flex;
    align-items: center;
    flex-direction: column;
    /* row-gap: 10px; */
  }
}

@media screen and (max-width: 730px) {
  .container {
    display: flex;
    align-items: center;
    flex-direction: column;
    /* row-gap: 10px; */
  }

  .link {
    margin-bottom: 10px;
  }

}

@media screen and (max-width: 550px) {
  .container {
    display: flex;
    align-items: center;
  }

  .link {
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 20px;
  }
}