.section {
  background-color: var(--color-background);
}

.container {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
  max-width: 1200px;
}

.wrapper {
  align-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 20px;
  width: 100%;
  max-width: 800px;
}

.descriptionContainer {
  margin-bottom: 70px;
  width: 100%;
  max-width: 500px;
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  font-feature-settings: 'pnum' on, 'lnum' on;
  color: var(--color-light-text);
}

.content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.phoneContainer {
  position: relative;
  height: 630px;
  width: 310px;
}

.textContainer {
  position: relative;
  width: 100%;
  max-width: 350px;
  height: 110px;
}

@media screen and (max-width: 1210px) {
  .content {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media screen and (max-width: 1024px) {
  .container {
  }

  .content {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: center;
    flex-wrap: wrap;
    row-gap: 30px;
    height: 630px;
  }

  .textContainer {
    max-width: 340px;
  }

  .phoneContainer {
    order: 2;
  }
}

@media screen and (max-width: 768px) {
  .content {
    gap: 20px;
  }

  .wrapper {
    padding-left: 34px;
    padding-right: 34px;
  }

  .descriptionContainer {
    margin-bottom: 50px;
  }
}

@media screen and (max-width: 738px) {
  .container {
    margin-bottom: 50px;
  }

  .content {
    height: auto;
    align-items: center;
    padding-left: 0;
    padding-right: 0;
  }

  .pickerContainer {
    width: 100%;
  }

  .descriptionContainer {
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 440px) {
  .phoneContainer {
    height: 450px;
    width: 200px;
  }

  .textContainer {
    max-width: 340px;
  }

  .descriptionContainer {
    text-align: start;
  }
}

@media screen and (max-width: 320px) {
  .container {
    row-gap: 20px;
  }

  .wrapper {
    row-gap: 10px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .descriptionContainer {
    margin-bottom: 0px;
    font-size: 14px;
    line-height: 20px;
  }
}