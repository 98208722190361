.container {
  display: none;
  background-color: transparent;
  cursor: pointer;
}

.icon {
  fill: var(--color-white);
}

.scrolled {
  fill: var(--color-purple);
}

@media screen and (max-width: 768px) {
  .container {
    display: block;
  }
}