.mzr_first_banner {
    background: #FBBF37;
    border-radius: 10px;
    max-width: 768px;
    margin: 18px auto;
    padding: 23px 60px 1px 60px;
}

@media (max-width: 520px) {
    .mzr_first_banner {
        padding: 23px 20px 1px 20px;
    }

}

.mzr_first_step {
    background: #FF772F;
    border-radius: 45px;
    margin-bottom: 22px;
}

.mzr_first_step {
    display: flex;
    justify-content: space-between;
    color: #fff;
    align-items: center;
}

.mzr_first_step_one {
    width: 133px;
    text-align: center;
    font-size: 35px;
    font-family: 'Fontatica4F', Arial, sans-serif;
    margin-left: 6px;
}



.mzr_first_step_two {
    border-left: 5px dashed #FBBF37;
    padding-left: 26px;
    width: -webkit-fill-available;
}

.mzr_first_step_title {
    font-size: 33px;
    line-height: 34px;
    font-family: 'Fontatica4F', Arial, sans-serif;
    padding: 19px 0 9px 0;
}

@media (max-width: 730px) {

    .mzr_first_step_title {
        padding-right: 30px;
    }
}

.mzr_first_step_text {
    font-size: 24px;
    font-family: 'Roboto Thin';
    padding-right: 30px;
    line-height: 28px;
    padding-bottom: 20px;
}

.mzr_first_step_text strong {
    font-family: 'Roboto Medium';
    font-weight: 100;
}

.mzr_first_step_text span {
    font-size: 16px;
    font-style: italic;
}


@media (max-width: 520px) {


    .mzr_first_step_one {
        font-size: 18px;
        width: 80px;
    }

    .mzr_first_step_title {
        font-size: 23px;
        line-height: 26px;
        padding-right: 18px;
    }

    .mzr_first_step_text {
        font-size: 12px;
        line-height: 18px;
        padding-right: 18px;
        font-weight: 600;
    }

    .mzr_first_step_text strong {
        font-weight: 600;
        font-size: 12px;
    }

    .mzr_first_step_text span {
        font-size: 10px;
    }

}