.container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.title {
  padding-bottom: 20px;
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 26px;
  font-feature-settings: 'pnum' on, 'lnum' on;
}

@media screen and (max-width: 320px) {
  .title {
    padding-bottom: 15px;
  }
}
