.container {
    max-width: 1200px;
    margin: 0 auto;
    padding-top: 130px;

}

.background {
    background-color: var(--color-background);
}

.wrapper,
.wrapperPostAndVideo {
    padding-top: 30px;
    padding-bottom: 230px;
    position: relative;
}

.wrapper {
    padding-left: 100px;
    padding-right: 100px;
    display: flex;
    flex-direction: row;
}

.wrapperPostAndVideo {
    display: flex;
    flex-direction: column;
}

.videoWrapper {
    width: 25vw;
    max-width: 380px;
    min-width: 290px;
    border-radius: 30px;
}

.infoContainer {
    padding-left: 50px;
}

.time {
    padding-left: 15px;
    margin: 0;
    color: var(--color-grey);
    font-size: 16px;
}

.secondName,
.firstName,
h1 {
    margin: 0;
}

.shareIcon {
    position: absolute;
    top: -98px;
    right: 0px;
}

@media screen and (max-width: 920px) {
    .wrapper {
        padding-left: 0px;
        padding-right: 0px;
    }

    .container {
        padding-left: 30px;
        padding-right: 30px;
        padding-top: 134px;
    }

    .infoContainer {
        padding-left: 30px;
    }

    .videoWrapper {
        width: 100%;
    }
}

@media screen and (max-width: 700px) {
    .container {
        padding-top: 95px;
        padding-left: 15px;
        padding-right: 15px;
    }

    .videoWrapper {
        width: 100%;
        margin-bottom: 20px;
    }

    .wrapper {
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    .infoContainer {
        padding-left: 0px;
    }

    .favoriteContainer {
        display: flex;
        flex-direction: column;
        position: absolute;
        bottom: 260px;
        right: 0px;
    }

    .headerWrapper {
        display: flex;
        flex-direction: column;
    }
}

.titleContainer {
    max-width: 770px;
    min-width: 320px;
    display: flex;
    justify-content: space-between;
}