.container {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 240px;
  height: 84px;
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  text-transform: uppercase;
  font-feature-settings: 'pnum' on, 'lnum' on;
  color: var(--color-purple);
  background-color: transparent;
  border-radius: 50px;
  transition: all 0.3s ease;
  cursor: pointer;
}

.container:hover {
  background-color: var(--color-white);
}

@media screen and (max-width: 320px) {
  .container {
    max-width: 200px;
    height: 73px;
    font-size: 12px;
    line-height: 17px;
  }
}
