.videoWrapper {
    display: flex;
    flex-direction: row;
    max-width: 380px;
    width: 100%;
    height: 100%;
    max-height: 675px;
    min-height: 515px;
    min-width: 290px;
    max-width: 380px;
    border-radius: 30px;
    margin-bottom: 30px;
    position: relative;

}

@media screen and (max-width: 768px) {
    .videoWrapper {
        width: 100%;
    }
}

@media screen and (max-width: 550px) {
    .videoWrapper {
        width: 100%;
        margin-bottom: 10px;
    }
}

.videoImageContainer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    border-radius: 30px;
}

.videoImage {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 30px;
    object-fit: cover;
}


.startButton {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: transparent;
}