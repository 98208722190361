.container {
  display: flex;
  padding: 50px;
  width: 100%;
  max-width: 585px;
  height: 400px;
  border-radius: 40px;
  background-color: var(--color-white);
  box-shadow: 0px 10px 30px rgba(157, 141, 241, 0.2);
}

.wrapper {
  display: flex;
  flex-direction: column;
}

.title {
  font-family: 'Soyuz Grotesk';
  font-style: normal;
  font-weight: 700;
  font-size: 72px;
  line-height: 79px;
  letter-spacing: 0em;
  margin-block-start: 0em;
  margin-block-end: 0em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.text {
  padding-top: 30px;
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  font-feature-settings: 'pnum' on, 'lnum' on;
  text-align: start;
}

.link {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: auto;
  width: 100%;
  max-width: 236px;
  height: 52px;
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  text-transform: uppercase;
  font-feature-settings: 'pnum' on, 'lnum' on;
  color: var(--color-white);
  border-radius: 16px;
  background-color: var(--color-purple);
  box-shadow: 0px 8px 25px rgba(157, 141, 241, 0.4);
}

.icon {
  align-self: flex-end;
}

@media screen and (max-width: 1250px) {
  .container {
    max-width: 700px;
    height: 350px;
  }

  .text {
    padding-right: 50px;
  }
}


@media screen and (max-width: 768px) {
  .icon {
    width: 180px;
    height: 180px;
  }
}

@media screen and (max-width: 620px) {
  .container {
    flex-direction: column;
    align-items: flex-start;
    height: auto;
    padding: 30px;
  }

  .icon {
    align-self: flex-start;
    order: 1;
  }

  .wrapper {
    order: 2;
  }

  .link {
    margin-top: 20px;
    height: 47px;
    max-width: 250px;
  }
}


@media screen and (max-width: 420px) {
  .container {
    border-radius: 20px;
  }

  .title {
    padding-top: 20px;
    font-size: 48px;
    line-height: 53px;
  }

  .text {
    padding-top: 5px;
    font-size: 14px;
    line-height: 20px;
  }

  .icon {
    width: 100px;
    height: 100px;
  }
}