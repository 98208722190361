.container {
    position: relative;
    display: flex;
    align-items: flex-end;
    width: 100%;
    height: 52px;
    padding-right: 40px;
    background-color: var(--color-white);
    border-radius: 16px;
    border: 1px solid var(--color-background);
    transition: 0.3s all;
    cursor: pointer;
}

.container span {
    width: 100%;
    padding-left: 16px;
    padding-bottom: 8.5px;
    font-family: 'Raleway';
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    outline: 0;
    border: none;
    border-radius: 16px;
    transition: 0.3s all;
}

.container label {
    position: absolute;
    z-index: 1;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    color: var(--color-grey);
    pointer-events: none;
    transform: translate(0, -16px) scale(1);
    transform-origin: top left;
    transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    line-height: 1;
    left: 16px;
}

.containerError {
    border: 1px solid var(--color-red);
}

.containerError label {
    color: var(--color-red);
}

.focused label {
    transform: translate(0, -28.5px) scale(0.8);
}

.icon {
    position: absolute;
    top: 18px;
    right: 18px;
    transition: 0.3s all;
}

.customSelect {
    background-repeat: no-repeat;
    background-position: right center;
    padding-right: 20px;
    appearance: none;
    background-color: transparent;
    background-image: url('../../../../assets/icons/arrowRight.svg');
}

@media screen and (max-width: 320px) {
    .container {
        height: 47px;
        padding-right: 35px;
    }

    .container input {
        padding-bottom: 7.5px;
        font-size: 12px;
        line-height: 17px;
    }

    .container label {
        font-size: 12px;
        line-height: 17px;
        transform: translate(0, -12px) scale(1);
    }

    .focused label {
        transform: translate(0, -24.5px) scale(0.9);
    }
}