.container,
.containerPlanning,
.emptyContainer,
.planingChildContainer {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding-top: 150px;
    padding-bottom: 100px;
    background-color: var(--color-background);
}

.background {
    background-color: var(--color-background);
}

.planingChildContainer {
    padding-bottom: 216px;
}

.emptyContainer {
    padding-top: 148px;
    padding-bottom: 100px;
}

.containerPlanning {
    padding-bottom: 216px;
}

.wrapperPlanning {
    display: flex;
    justify-content: space-between;
}

.wrapper {
    display: flex;
    flex-direction: row;
}

.wrapperWeekly {
    display: flex;
    flex-direction: column-reverse;
    justify-content: start;
    width: 100%;
}

.statusPlanning,
.planingChildStatus {
    width: 100%;
    margin-left: 65px;
}

.statusTitle {
    margin-block-start: 0em;
    margin-block-end: 0em;
    padding-bottom: 30px;
    font-size: 36px;
    font-weight: 700;
    font-family: 'Raleway';
}

.status {
    margin-right: 65px;
}

.statusContainer {
    display: flex;
    align-self: center;
    align-items: center;
    justify-content: center;
}

.statusWrapper {
    max-width: 600px;
}

@media screen and (max-width: 1240px) {
    .wrapperPlanning {
        display: flex;
        flex-direction: column-reverse;
    }

    .container,
    .containerPlanning,
    .emptyContainer,
    .planingChildContainer {
        padding-left: 16px;
        padding-right: 16px;
        padding-top: 152px;
    }

    .status {
        width: 100%;
        max-width: 100%;
    }

    .statusPlanning,
    .planingChildStatus {
        width: 100%;
        margin-left: 0px;
    }

    .wrapper {
        display: flex;
        flex-direction: column;
    }

    .statusWrapper {
        max-width: 100%;
    }

    .planingChildContainer {
        padding-bottom: 70px;
    }


}

.weaklyContainer {
    display: flex;
    flex-direction: column;
    justify-content: start;
}

.meditationSecondContainer {
    /* display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 50px; */

}

@media screen and (max-width: 1260px) {
    .meditationSecondContainer {
        padding-top: 30px;
    }
}

@media screen and (max-width: 860px) {
    .meditationSecondContainer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .meditationSecondContainer {
        display: flex;
        flex-direction: row;
        margin-bottom: 50px;
        padding-top: 30px;
        height: 100%;
    }

    .weaklyContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}

@media screen and (max-width: 750px) {

    .meditationSecondContainer {
        display: flex;
        flex-direction: column;
    }
}

@media screen and (max-width: 550px) {

    .container,
    .containerPlanning,
    .emptyContainer {
        padding-top: 15px;
    }

    .statusTitle {

        font-size: 24px;

    }

    .planingChildContainer {
        padding-top: 15px;
    }

    .statusTitle {
        padding-bottom: 20px;
    }

    .containerPlanning {
        padding-bottom: 50px;
    }

    .planingChildStatus {
        padding-top: 20px;
    }

    .planingChildContainer {
        padding-bottom: 50px;
    }


}