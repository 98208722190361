.footer {
  padding-top: 50px;
  background: linear-gradient(83.01deg, #7460DC 10.92%, #9D8DF1 89.08%);
}

.container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  row-gap: 50px;
  column-gap: 70px;
  width: 100%;
  max-width: 1200px;
  height: 525px;
  margin: 0 auto;
}

.logoBlock {
  order: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 320px;
}

.logoIcon {
  width: 130px;
  height: 100px;
}

.logoText {
  padding-top: 10px;
  padding-bottom: 30px;
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 34px;
  font-feature-settings: 'pnum' on, 'lnum' on;
  color: var(--color-white);
  text-align: start;
}

.nameBlock {
  order: 2;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  width: 100%;
  max-width: 320px;
}

.name {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 26px;  
  font-feature-settings: 'pnum' on, 'lnum' on;
  color: var(--color-white);
}

.unp {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  font-feature-settings: 'pnum' on, 'lnum' on;
  color: var(--color-light-purple);
}

.rightBlock {
  order: 3;
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  font-feature-settings: 'pnum' on, 'lnum' on;
  color: var(--color-light-purple);
  width: 100%;
  max-width: 320px;
  margin-top: auto;
  margin-bottom: 50px;
}

.contactsBlock {
  position: relative;
  order: 4;
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  width: 100%;
  max-width: 320px;
}

.anchor {
  position: absolute;
  top: -130px;
}

.contactsTitle {
  font-family: 'Soyuz Grotesk';
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 58px;
  letter-spacing: 0.03em;
  color: var(--color-white);
}

.contactsPhone {
  display: flex;
  flex-direction: column;
  row-gap: 10px; 
}

.phone {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 26px;
  font-feature-settings: 'pnum' on, 'lnum' on;
  color: var(--color-white);
}

.phoneDescription {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  font-feature-settings: 'pnum' on, 'lnum' on;
  color: var(--color-light-purple);
}

.contactsLink {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  text-decoration-line: underline;
  font-feature-settings: 'pnum' on, 'lnum' on;
  color: var(--color-white);
}

.adressBlock {
  order: 5;
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  width: 100%;
  max-width: 320px;
}

.adress {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.adressTitle {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  font-feature-settings: 'pnum' on, 'lnum' on;
  color: var(--color-white);
}

.adressDescription {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  font-feature-settings: 'pnum' on, 'lnum' on;
  color: var(--color-light-purple);
}

.phoneBlock {
  order: 6;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 420px;
  height: 525px;
  padding-top: 70px;
  background-image: url('../../assets/images/phoneFooter.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.phoneBlockTitle {
  font-family: 'Soyuz Grotesk';
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 58px;
  text-align: center;
  letter-spacing: 0.03em;
}

.phoneBlockTitleColor {
  color: var(--color-white);
  background-color: var(--color-purple);
}

.linksContainer {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  padding-top: 30px;
  padding-bottom: 46px;
}

.docsContainer {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.docLink {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  text-decoration-line: underline;
  font-feature-settings: 'pnum' on, 'lnum' on;
  color: var(--color-grey);
}

.rightBlockGrey {
  display: none;
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
  text-align: center;
  font-feature-settings: 'pnum' on, 'lnum' on;
  color: var(--color-grey);
}

@media screen and (max-width: 1250px) {
  .container {
    column-gap: 50px;
  }
}

@media screen and (max-width: 1225px) {
  .container {
    column-gap: 0;
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media screen and (max-width: 1152px) {
  .container {
    height: 701px;
    padding-left: 34px;
    padding-right: 34px;
  }

  .nameBlock { order: 2; }

  .adressBlock { order: 3; }

  .rightBlock {
    order: 4;
    margin-bottom: 35px;
  }

  .contactsBlock { 
    order: 5;
    max-width: 325px;
  }

  .logoIcon {
    width: 104px;
    height: 80px;
  }

  .logoText {
    font-size: 20px;
    line-height: 28px;
  }

  .contactsTitle {
    font-size: 36px;
    line-height: 43px;
  }

  .phoneBlock {
    padding-top: 50px;
    max-width: 325px;
    height: 420px;
  }

  .phoneBlockTitle {
    font-size: 36px;
    line-height: 43px;
  }

  .linksContainer {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .docsContainer {
    row-gap: 15px;
  }
}


@media screen and (max-width: 708px) {
  .container {
    row-gap: 40px;
    align-items: center;
    height: auto;
    padding-left: 10px;
    padding-right: 10px;
  }

  .logoBlock {
    align-items: center;
  }

  .contactsBlock { 
    order: 2;
    row-gap: 20px;
    align-items: center;
  }

  .nameBlock { order: 3; }

  .adressBlock { 
    order: 4;
    row-gap: 20px;
    align-items: center;
  }

  .rightBlock { display: none; }

  .logoIcon {
    width: 90px;
    height: 70px;
  }

  .logoText {
    padding-bottom: 20px;
    text-align: center;
  }

  .nameBlock {
    align-items: center;
    row-gap: 5px;
  }

  .name {
    font-size: 20px;
    line-height: 22px;  
  }

  .unp {
    font-size: 14px;
    line-height: 20px;
  }

  .rightBlock {
    font-size: 12px;
    line-height: 17px;
    color: var(--color-grey);
  }

  .contactsTitle {
    font-size: 24px;
    line-height: 26px;
  }

  .contactsPhone {
    row-gap: 5px; 
  }

  .phone {
    font-size: 20px;
    line-height: 22px;
  }

  .phoneDescription {
    font-size: 14px;
    line-height: 20px;
  }

  .contactsLink {
    font-size: 16px;
    line-height: 18px;
  }

  .adress {
    row-gap: 5px;
    align-items: center;
  }

  .adressTitle {
    font-size: 16px;
    line-height: 18px;
  }

  .adressDescription {
    font-size: 14px;
    line-height: 20px;
    text-align: center;
  }

  .phoneBlock {
    padding-top: 50px;
    max-width: 290px;
    height: 380px;
  }

  .phoneBlockTitle {
    font-size: 28px;
    line-height: 34px;
  }

  .linksContainer {
    row-gap: 10px;
  }

  .docLink {
    font-size: 12px;
    line-height: 17px;
  }

  .rightBlockGrey {
    display: block;
  }
}
