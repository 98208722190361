.main {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-top: 130px;
  padding-bottom: 100px;
  background-color: var(--color-background);
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  width: 100%;
  max-width: 1200px;
}

.section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  width: 100%;
  max-width: 1000px;
}

.wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  column-gap: 20px;
}

.commonInfoContainer {
  display: flex;
  flex-direction: column;
  row-gap: 50px;
}

.commonInfoBlock {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.statusContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 475px;
}

.menuContainer {
  margin-top: 70px;
  width: 100%;
}

@media screen and (max-width: 1200px) {
  .container {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media screen and (max-width: 930px) {
  .wrapper {
    flex-direction: column;
  }

  .commonInfoContainer {
    margin-bottom: 50px;
  }

  .commonInfoBlock {
    display: flex;
    flex-direction: row;
    gap: 20px;
  }

  .statusContainer {
    max-width: 930px;
  }
}

@media screen and (max-width: 768px) {
  .main {
    padding-bottom: 70px;
  }

  .container {
    padding-left: 34px;
    padding-right: 34px;
  }

  .menuContainer {
    margin-top: 50px;
  }
}

@media screen and (max-width: 740px) {
  .commonInfoBlock {
    flex-direction: column;
  }
}

@media screen and (max-width: 320px) {
  .main {
    padding-top: 95px;
    padding-bottom: 50px;
  }

  .container {
    padding-left: 15px;
    padding-right: 15px;
  }

  .commonInfoContainer {
    row-gap: 30px;
    margin-bottom: 30px;
  }

  .menuContainer {
    margin-top: 30px;
  }
}
