.container {
  width: 100%;
  max-width: 240px;
  height: 84px;
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  text-transform: uppercase;
  font-feature-settings: 'pnum' on, 'lnum' on;
  color: var(--color-white);
  background-color: var(--color-purple);
  border-radius: 50px;
  transition: all 0.3s ease;
  box-shadow: 0px 8px 25px rgba(157, 141, 241, 0.4);
}

.container:disabled {
  opacity: 0.5;
}

.container:hover {
  background-color: var(--color-purple-hover);
}

@media screen and (max-width: 550px) {
  .container {
    max-width: 200px;
    height: 73px;
    font-size: 12px;
    line-height: 17px;
  }
}