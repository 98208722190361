.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 20px;
  height: 95px;
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  font-size: 1.4rem;
  line-height: 2rem;
  text-transform: uppercase;
  font-feature-settings: 'pnum' on, 'lnum' on;
  cursor: pointer;
}

.container:hover .wrapper {
  background-color: var(--color-purple);
}

.container:hover .arrowContainer {
  fill: var(--color-white);
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45px;
  height: 95px;
  border-radius: 50px;
  transition: all 0.3s ease;
  background-color: var(--color-white);
}

.arrowContainer {
  width: 15px;
  height: 15px;
  transition: all 0.3s ease;
}

.title {
  color: var(--color-black);
}

@media screen and (max-width: 440px) {
  .container {
    column-gap: 15px;
    height: 60px;
    font-size: 1.2rem;
    line-height: 1.7rem;
  }

  .wrapper {
    width: 30px;
    height: 60px;
  }

  .arrowContainer {
    width: 10px;
    height: 10px;
  }
}