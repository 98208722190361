.latePregnancy {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding-top: 147px;
    padding-bottom: 321px;
}

.containerPlanning {
    padding-bottom: 216px;
}

.background {
    background-color: var(--color-background);
}

.wrapperPlanning {
    display: flex;
    justify-content: space-between;
}

.wrapper {
    display: flex;
    flex-direction: row;
}

.wrapperWeekly {
    display: flex;
    flex-direction: column-reverse;
    justify-content: start;
    width: 100%;
}

.statusPlanning {
    width: 100%;
    margin-left: 65px;
}

.statusTitle {
    margin-block-start: 0em;
    margin-block-end: 0em;
    padding-bottom: 30px;
    font-size: 36px;
    font-weight: 700;
    font-family: 'Raleway';
}

.status {
    margin-right: 65px;
}

.statusContainer {
    display: flex;
    align-self: center;
    align-items: center;
    justify-content: center;
}

.statusWrapper {
    max-width: 600px;
}

@media screen and (max-width: 768px) {
    .wrapperPlanning {
        display: flex;
        flex-direction: column-reverse;
    }

    .latePregnancy {
        padding-left: 30px;
        padding-right: 30px;
        padding-top: 151px;
        padding-bottom: 417px;
    }

    .status {
        width: 100%;
        max-width: 100%;
    }

    .statusPlanning {
        width: 100%;
        margin-left: 0px;
    }

    .wrapper {
        display: flex;
        flex-direction: column;
    }

    .statusWrapper {
        max-width: 100%;
    }
}


@media screen and (max-width: 550px) {

    .latePregnancy {
        padding-left: 15px;
        padding-right: 15px;
        padding-top: 95px;
    }

}