.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 70px;
  width: 100%;
}

.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  width: 100%;
}

.visibleContainer {
  overflow: hidden;
  width: 1000px;
}

.list {
  display: inline-flex;
  white-space: nowrap;
  transition: all 0.5s ease-in-out;
}

.item {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 300px;
  padding: 0 25px;
  margin-right: 50px;
}

.displayName {
  margin-top: 20px;
  margin-bottom: 5px;
  word-break: break-word;
  white-space: pre-line;
  overflow-wrap: break-word;
  -ms-word-break: break-word;
  word-break: break-word;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  font-size: 21px;
  line-height: 24px;
  text-align: center;
  font-feature-settings: 'pnum' on, 'lnum' on;
}

.position {
  word-break: break-word;
  white-space: pre-line;
  overflow-wrap: break-word;
  -ms-word-break: break-word;
  word-break: break-word;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  font-feature-settings: 'pnum' on, 'lnum' on;
  color: var(--color-light-text);
}

.arrowContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45px;
  height: 95px;
  border-radius: 50px;
  transition: all 0.3s ease;
  background-color: var(--color-purple);
}

.disableArrowContainer {
  background-color: var(--color-light-purple);
}

.arrow {
  width: 15px;
  height: 15px;
  fill: var(--color-white);
}

.dotList {
  display: flex;
  align-items: center;
  column-gap: 5px;
}

.dot {
  width: 8px;
  height: 8px;
  border-radius: 8px;
  background-color: var(--color-light-purple);
  transition: all 0.5s ease;
}

.activeDot {
  width: 40px;
  height: 12px;
  background-color: var(--color-purple);
}

.image {
  width: 180px;
  height: 180px;
}

@media screen and (max-width: 1140px) {
  .wrapper {
    max-width: 700px;
  }

  .visibleContainer  {
    width: 570px;
  }

  .item {
    align-items: flex-start;
    width: 270px;
    margin-right: 30px;
    padding: 0;
  }

  .image {
    width: 200px;
    height: 200px;
  }
}

@media screen and (max-width: 750px) {
  .visibleContainer  {
    width: 100%;
    max-width: 320px;
  }

  .item {
    align-items: center;
    width: 320px;
    margin: 0;
    padding: 0;
  }

  .image {
    width: 180px;
    height: 180px;
  }

  .arrowContainer {
    width: 30px;
    height: 60px;
  }
}

@media screen and (max-width: 420px) {
  .item {
    max-width: 310px;
  }
}

@media screen and (max-width: 380px) {
  .item {
    max-width: 300px;
  }

  .displayName {
    font-size: 19px;
    line-height: 22px;
  }
}

@media screen and (max-width: 360px) {
  .item {
    max-width: 270px;
  }
}

@media screen and (max-width: 340px) {
  .item {
    max-width: 250px;
  }
}

@media screen and (max-width: 330px) {
  .item {
    max-width: 240px;
  }
}
