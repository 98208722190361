.container {
  display: flex;
  align-items: center;
  column-gap: 16px;
  padding: 16px;
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: start;
  font-feature-settings: 'pnum' on, 'lnum' on;
  color: var(--color-white);
  border-radius: 16px;
  background-color: var(--color-red);
}

.icon {
  align-self: flex-start;
  min-width: 24px;
  height: 24px;
  background-image: url('../../../assets/icons/wrongLight.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

@media screen and (max-width: 550px) {
  .container {
    column-gap: 10px;
    font-size: 12px;
    line-height: 17px;
  }

  .icon {
    min-width: 16px;
    height: 16px;
  }
}