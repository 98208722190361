.container {

    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;

}

.animationContainer {
    width: 122px;
    height: 122px;
    margin: -15px;
}

.loading {
    font-size: 24px;
    font-weight: 700;
    font-family: 'Raleway';
    margin-block-start: 0em;
    margin-block-end: 0em;
}