.container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding-top: 130px;

}

.wrapper {
    padding-top: 30px;
    padding-bottom: 100px;
    display: flex;
    flex-direction: column;
    position: relative;
}

.videoContentInfoWrapper {
    width: 100%;
    max-width: 770px;
}

.wrapperPostAndVideo {
    padding-top: 30px;
    padding-bottom: 100px;
    display: flex;
    flex-direction: column;
    position: relative;
}

.time {
    padding-left: 15px;
    color: var(--color-grey);
    font-size: 16px;
    margin-block-start: 0em;
    margin-block-end: 0em;
}

h1 {
    margin-block-start: 0em;
    margin-block-end: 0em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}

.shareIcon {
    position: absolute;
    top: -98px;
    right: 0px;
}


.videoContentWrapper {
    display: flex;
    flex-direction: row;
}

.title {
    padding-top: 30px;
}

.background {
    background-color: var(--color-background);
}

@media screen and (max-width: 1200px) {
    .wrapper {
        padding-left: 0px;
        padding-right: 0px;
    }

    .container {
        padding-left: 30px;
        padding-right: 30px;
        padding-top: 134px;
    }

    .videoContentWrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}


@media screen and (max-width: 440px) {

    .container {
        padding-top: 95px;
    }

    .container {
        padding-left: 15px;
        padding-right: 15px;

    }

    .wrapper {

        display: flex;
        flex-direction: column;
        position: relative;
    }

    .favoriteContainer {
        display: flex;
        flex-direction: column;
        position: absolute;
        bottom: 260px;
        right: 0px;
    }

}