.containerPlanning {
    background-color: var(--color-white);
    width: 100%;
    display: flex;
    align-items: center;
    border-radius: 20px;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 27px;
    padding-bottom: 27px;
    margin-bottom: 50px;
}


.emptyContainer,
.containerPlanningPregnancy {
    background-color: var(--color-white);
    width: 100%;
    display: flex;
    align-items: center;
    border-radius: 30px;
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-bottom: 35px;
}

.emptyContainer {
    border-radius: 20px;
    padding: 27px 30px 27px 30px;
}

.containerPlanningPregnancy {
    display: flex;
    justify-content: space-between;

}

.container {
    display: flex;
    align-items: center;
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: var(--color-white);
    width: 100%;
    border-radius: 20px;
    margin-bottom: 50px;

}

@media screen and (max-width: 768px) {
    .containerPlanning {
        margin-bottom: 50px;
    }

    .containerPlanning {
        padding-top: 15px;
        padding-bottom: 15px;

    }

    .emptyContainer {
        border-radius: 20px;
        padding: 15px 30px 15px 30px;
    }

}

@media screen and (max-width: 550px) {
    .containerPlanning {
        padding-top: 15px;
        padding-bottom: 15px;
        padding-left: 20px;
        margin-bottom: 30px;
    }

    .container {
        margin-bottom: 30px;
        padding: 20px 30px 20px 30px;
    }

    .afterChildBirthDescription {
        font-size: 20px;
        font-weight: 600;

    }

    .emptyContainer {
        width: 100%;
        padding: 15px 20px 15px 20px;
        margin-bottom: 30px;
    }

    .containerPlanningPregnancy {
        display: flex;
        flex-direction: column;
        align-items: start;
        padding: 20px 30px 20px 30px;
        border-radius: 20px;
    }

    .containerPlanningPregnancy {
        display: flex;
        justify-content: space-between;
        margin-bottom: 30px;
    }

}