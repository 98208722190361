.container {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  width: 100%;
  max-width: 320px;
}

.wrapper {
  position: relative;
  display: flex;
  align-items: center;
  column-gap: 16px;
  padding: 0 24px;
  height: 70px;
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  font-feature-settings: 'pnum' on, 'lnum' on;
  border-radius: 16px;
  border: 1px solid var(--color-white);
  background-color: var(--color-white);
  transition: 0.3s ease-out;
}

.selected {
  border-color: var(--color-purple);
}

.check {
  position: absolute;
  left: -10px;
  top: -15px;
  opacity: 0;
  transition: 0.3s ease-out;
}

.selected .check {
  opacity: 1;
}

@media screen and (max-width: 320px) {
  .container {
    row-gap: 15px;
  }

  .wrapper {
    height: 72px;
  }
}
