.container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.withoutButton {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
}

.titleContainer {
    width: 100%;
    line-height: 57px;
    letter-spacing: 1px;
    display: flex;
}

.title,
.withoutButton {
    width: calc(100% - 130px);
    font-size: 48px;
    font-weight: 700;
    text-align: center;
    text-transform: lowercase;
    font-family: 'Soyuz Grotesk';
}


.withoutButton {
    display: flex;
    justify-content: center;
    width: 100%;
}

@media screen and (max-width: 768px) {
    .titleContainer {
        margin-left: 11px;
    }

    .icon {
        display: flex;
        align-items: center;
    }

    .arrowContainer {
        display: flex;
        justify-content: space-between;
    }
}

@media screen and (max-width: 550px) {

    .title,
    .withoutButton {
        font-size: 28px;
        width: 100%;
        line-height: 33px;
        left: 0px;
    }

    .titleContainer {
        margin-left: 0;
    }

    .arrowContainer {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    .arrowContainerWithoutButton {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    .container {
        display: flex;
        width: 100%;
        flex-direction: column;
    }

    .icon {
        display: flex;
        align-items: center;
    }

}