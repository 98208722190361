.container {
    background-color: var(--color-white);
    border-radius: 20px;
    width: 100%;
    max-width: 320px;
    padding: 20px;
    margin-bottom: 20px;
    position: relative;
}


.imageContainer {
    border-radius: 15px;
    width: 100%;
    height: 100%;
    max-width: 280px;
    max-height: 200px;
}

.title {
    margin-block-start: 0em;
    margin-block-end: 0em;
    font-size: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    font-weight: 500;
}

.containerSvg {
    top: 30px;
    position: absolute;

}

.blockFavoriteButton {
    background-color: transparent;
    padding: 0px;
    padding-left: 10px;
}

.bookButton {
    padding-left: 10px;
    padding-right: 5px;
}

@media screen and (max-width: 768px) {
    .container {
        width: 100%;
        max-width: 335px;
    }

    .imageContainer {
        width: 100%;
        max-width: 295px;
    }
}