.container {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

@media screen and (max-width: 681px) {
  .container {
    position: static;
    order: 2;
    transform: translate(0%);
    min-width: 440px;
    margin: 20px auto 0;
    text-align: center;
  }
}

@media screen and (max-width: 510px) {
  .container {
    min-width: 0px;
  }
}

@media screen and (max-width: 320px) {
  .container {
   
  }
}
