.container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding-top: 130px;
    margin-bottom: 80px;
}

.containerPageAdvice {}

.wrapper {
    padding-top: 30px;
    padding-bottom: 100px;
    display: flex;
    justify-content: space-between;
}

.status {
    width: 100%;
    max-width: 475px;
}

.statusTitle {
    margin-block-start: 0em;
    margin-block-end: 0em;
    padding-bottom: 30px;
    font-size: 36px;
    font-weight: 700;
    font-family: 'Raleway';
}

@media screen and (max-width: 768px) {
    .wrapper {
        display: flex;
        flex-direction: column-reverse;
    }

    .container {
        padding-left: 30px;
        padding-right: 30px;
        padding-top: 134px;
        margin-bottom: 50px;
    }

    .status {
        width: 100%;
        max-width: 100%;
    }

}


@media screen and (max-width: 550px) {

    .container {
        padding-top: 95px;
        margin-bottom: 30px;
    }

    .statusTitle {

        font-size: 24px;

    }


}

.background {
    background-color: var(--color-background);
}