input.container {
  padding: 0;
  padding-bottom: 10px;
  /* width: 100%; */
  width: 60px;
  height: 67px;
  font-family: 'Soyuz Grotesk';
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 53px;
  text-align: center;
  outline: 0;
  border: none;
  background-color: var(--color-white);
  border-bottom: 4px solid var(--color-light-grey);
  transition: 0.3s all;
  caret-color: var(--color-purple);
}

input.container:focus {
  border-color: var(--color-purple);
}

input.container::placeholder {
  color: var(--color-light-grey);
}

input.container:focus::placeholder {
  color: transparent;
}

input.containerError {
  color: var(--color-red);
  border-color: var(--color-red);
}

@media screen and (max-width: 320px) {
  input.container {
    width: 40px;
    height: 54px;
    font-size: 36px;
    line-height: 40px;
  }
}
  