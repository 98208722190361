.container {
  width: 100%;
  width: 296px;
  height: 79px;
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 39px;
  text-align: center;
  text-transform: uppercase;
  font-feature-settings: 'pnum' on, 'lnum' on;
  color: var(--color-white);
  background-color: var(--color-purple);
  border-radius: 24px;
  transition: all 0.3s ease;
  box-shadow: 0px 8px 25px rgba(157, 141, 241, 0.4);
  color: var(--color-white);
  display: flex;
  justify-content: center;
  align-items: center;
}

.container:hover {
  background-color: var(--color-purple-hover);
}

@media screen and (max-width: 320px) {
  .container {
    width: 221px;
    height: 62;
    font-size: 16px;
    line-height: 22px;
  }
}