.container {
    position: relative;
}

.copied,
.error,
.loading {
    position: absolute;
    height: 47px;
    border-radius: 16px;
    color: var(--color-white);
    z-index: 40;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    padding: 0px;
    font-weight: 500;
    font-size: 14px;
    padding-right: 16px;
    padding-left: 16px;
    width: fit-content;
    right: 16px;
    top: -32px;
}

.text {
    padding-left: 16px;
    white-space: nowrap;
}

.error {
    background-color: #F57C1F;
}

.copied {
    background-color: var(--color-green);
    color: var(--color-white);
}

.loading {
    background-color: var(--color-white);
    color: var(--color-text);
}

@media screen and (max-width: 768px) {

    .copied,
    .error,
    .loading {
        top: 28px;
        right: 16px;
    }
}

@media screen and (max-width: 550px) {

    .copied,
    .error,
    .loading {
        top: 26px;
        right: 0;
    }
}