.text {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  align-items: start;
  font-feature-settings: 'pnum' on, 'lnum' on;
  color: var(--color-text);
  opacity: 0;
  transition: all 0.5s ease-in;
}

.show {
  opacity: 1;
}

@media screen and (max-width: 440px) {
  .text {
    left: 10px;
    right: 10px;
  }
}
