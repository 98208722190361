.text {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  font-feature-settings: 'pnum' on, 'lnum' on;
}

@media screen and (max-width: 320px) {
  .text {
    font-size: 12px;
    line-height: 17px;
  }
}
