.section {
  padding-bottom: 100px;
  background-color: var(--color-background);
}

.container {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
  max-width: 1200px;
}

@media screen and (max-width: 1250px) {
  .container {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media screen and (max-width: 768px) {
  .section {
    padding-bottom: 70px;
  }
  
  .container {
    padding-left: 34px;
    padding-right: 34px;
  }
}

@media screen and (max-width: 480px) {
  .container {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media screen and (max-width: 320px) {
  .section {
    padding-bottom: 50px;
  }
}
