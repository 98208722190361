.certificate-title {
  margin-top: 0;
}

html {
  background-color: white;
}

.container {
  padding: 60px;
}

.image-small {
  display: none;
}

.buttons-container {
  display: flex;
  flex-direction: row;
  justify-content: start;
}

.wrapper {
  max-width: 470px;
  margin-right: 30px;
  margin-bottom: 50px;
}

.image {
  min-width: 360px;
  width: 470px;
  border-radius: 20px;
}

.btn-retutn {
  border-radius: 5px;
  background-color: #fff;
  font-size: 20px;
  width: fit-content;
  color: #0070c9;
  font-weight: 450;
  border: 2px solid #0070c9;
  height: 70px;
  width: 330px;
}

.download-button {
  background-color: #0070c9;
  width: max-content;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70px;
  border: 2px solid #0070c9;
  color: white;
  font-size: 20px;
  border-radius: 5px;
  font-weight: 500;
  width: 330px;
  margin-right: 30px;
}

.button-filler {
  width: 18px;
}

.certificate-ready-text {
  font-family: 'Montserrat', Arial, Helvetica, sans-serif;
  max-width: 470px;
  font-size: 20px;
}


@media (max-width:970px) {
  .buttons-container {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-content: start;
    align-items: start;
    align-self: flex-start;
    width: auto;
  }

  .btn-retutn {
    margin-top: 20px;
  }

  .certificate-ready-text {
    font-family: 'Montserrat', Arial, Helvetica, sans-serif;
    max-width: 100%;
    font-size: 20px;
  }

  .wrapper {
    max-width: 100%;
    margin: 0;
  }

  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
  }

  .image-small {
    display: block;
    width: 600px;
    height: 460px;
    position: absolute;
    right: -151px;
    margin-top: -20px;
    z-index: -1;
  }

  .image {
    display: none;
  }

}

@media (max-width:530px) {
  .container {
    padding: 40px 0;
  }

  .buttons-container {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 300px;
  }

  .certificate-title {
    margin-left: 15px !important;
    margin-right: 15px !important;
  }

  .certificate-ready-text {
    font-size: 14px;
    margin-left: 15px;
    margin-right: 15px;
  }

  .btn-retutn,
  .download-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 15px 30px;
    gap: 10px;
    height: 50px;
    width: calc(100% - 30px);
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    margin-left: 15px;
  }

  .button-filler {
    width: 15px;
  }

  .container {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-self: center;
  }

  .image-small {
    display: block;
    width: 400px;
    height: 305px;
    position: absolute;
    bottom: 0;
    right: -140px;
    z-index: -1;
  }

  .image {
    display: none;
  }

  .container {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-self: center;
  }
}