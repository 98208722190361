.container {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 15px 30px;
  border-radius: 20px;
  background-color: var(--color-white);
}

.bigContainer {
  padding: 20px 30px;
}

.iconLeft {
  width: 45px;
  height: 45px;
}

.bigIconLeft {
  width: 60px;
  height: 60px;
}

.title {
  flex-grow: 1;
  margin-left: 15px;
  margin-right: auto;
  max-width: 286px;
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  text-align: start;
  font-feature-settings: 'pnum' on, 'lnum' on;
}

.containerIconRight {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  width: 34px;
  height: 70px;
  border-radius: 50px;
  background-color: var(--color-background);
}

.iconRight {
  width: 10px;
  height: 10px;
}

.selected {
  border: 2px solid var(--color-purple);
}

.unselected {
  border: 2px solid var(--color-red);
}

.unselectedTitle {
  color: var(--color-red);
}

@media screen and (max-width: 930px) {
  .bigContainer {
    padding: 15px 20px;
  }

  .iconLeft {
    width: 60px;
    height: 60px;
  }

  .bigIconLeft {
    width: 40px;
    height: 40px;
  }

  .containerIconRight {
    min-width: 45px;
    height: 85px;
  }

  .iconRight {
    width: 15px;
    height: 15px;
  }

  .title {
    max-width: 100%;
    margin-left: 20px;
  }
}

@media screen and (max-width: 410px) {
  .container {
    padding: 15px 20px;
  }

  .iconLeft {
    width: 45px;
    height: 45px;
    margin-right: 20px;
  }

  .bigIconLeft {
    width: 40px;
    height: 40px;
    margin-right: 15px;
  }

  .title {
    font-size: 14px;
    line-height: 20px;
    margin-left: 0;
  }

  .containerIconRight {
    min-width: 34px;
    height: 70px;
  }

  .iconRight {
    width: 10px;
    height: 10px;
  }
}
