.favoriteContainer {
    display: flex;
    flex-direction: row;
}



@media screen and (max-width: 550px) {
    .favoriteContainer {
        display: flex;
        flex-direction: column;
        align-self: center;
        justify-content: center;
        width: 100%;
    }
}