.container {
    background-color: var(--color-white);
    width: 100%;
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: space-between;
    border-radius: 20px;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 15px;
    padding-bottom: 15px;
    margin-bottom: 30px;
}

.link {
    color: '#3C3C3B';
    width: 100%;
}

.title {
    font-size: 24px;
    padding-left: 15px;
    font-weight: 600;
    color: #3C3C3B;
}

.iconContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
}

@media screen and (max-width: 768px) {
    .container {
        margin-bottom: 50px;
    }

    .container {
        padding-top: 15px;
        padding-bottom: 15px;

    }


}

@media screen and (max-width: 550px) {
    .container {
        padding-top: 15px;
        padding-bottom: 15px;
        padding-left: 20px;
        margin-bottom: 30px;
        margin-top: 0;
    }

    .title {
        font-size: 14px;
        padding-left: 15px;
        font-weight: 600;
        color: #3C3C3B;
    }
}