.container {
    border-radius: 12px;
    background-color: var(--color-white);
    padding: 10px 30px;
    margin-right: 20px;
    margin-top: 30px;
    font-size: 18px;
    font-weight: 500;
    line-height: 25px;
    color: var(--color-black);
}

.container:hover {
    background-color: #E8E3FF;
}


.activeContainer {
    border-radius: 12px;
    background-color: var(--color-purple);
    padding: 10px 30px;
    margin-right: 20px;
    margin-top: 30px;
    font-size: 18px;
    font-weight: 500;
    line-height: 25px;
    color: var(--color-white);
}

@media screen and (max-width: 768px) {

    .container,
    .activeContainer {
        font-size: 16px;
        padding: 10px 20px;
        margin-right: 15px;
        margin-top: 80px;
        line-height: 22px;
    }
}

@media screen and (max-width: 550px) {

    .container,
    .activeContainer {
        font-size: 12px;
        margin-right: 10px;
        margin-top: 30px;
        margin-bottom: 0;

        padding-left: 15px;
        padding-right: 15px;
        padding-top: 7px;
        padding-bottom: 7px;
        font-weight: 500;
        width: fit-content;
        white-space: nowrap;
    }

    .coursesList {
        display: flex;
        flex-direction: row;
        max-height: 400px;
        overflow: auto;
        width: 100%;
    }

    .coursesList::-webkit-scrollbar {

        background-color: transparent;
    }

    .coursesList::-webkit-scrollbar-thumb {
        background-color: transparent;
    }
}