.titleContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.icons {
    display: flex;
    flex-direction: column;

}

.title {
    font-size: 28px;
    line-height: 33px;
    font-weight: 500;
    font-family: 'Raleway';
    margin-block-start: 0em;
    margin-block-end: 0em;
}

@media screen and (max-width: 550px) {

    .title {
        line-height: 24px;
        font-size: 20px;
    }
}