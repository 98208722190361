.container {
  position: absolute;
  left: calc(50% - 120px);
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 6px;
  margin: 0 auto;
  width: 100%;
  max-width: 240px;
  height: 56px;
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 26px;
  text-align: center;
  font-feature-settings: 'pnum' on, 'lnum' on;
  border-radius: 50px;
  background-color: var(--color-white);
}

.wrapperColor {
  color: var(--color-purple);
}

.wrapperGrey {
  color: var(--color-light-grey);
}

@media screen and (max-width: 768px) {
  .container {
    left: calc(50% - 110px);
    max-width: 220px;
    height: 46px;
  }
}

@media screen and (max-width: 564px) {
  .container {
    position: static;
    order: 2;
  }
}

@media screen and (max-width: 320px) {
  .container {
    max-width: 145px;
    height: 42px;
    font-size: 20px;
    line-height: 22px;
  }
}
