.container {
    background-color: var(--color-white);
    max-width: 490px;
    height: 100%;
    border-radius: 30px;
    padding: 0px 0px 50px 50px;
    box-shadow: 0px 4px 25px rgba(157, 141, 241, 0.2);
    margin-left: 50px;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.description {
    font-size: 16px;
    font-weight: 500;
    font-family: 'Raleway';
    color: var(--color-light-text);
    margin-block-start: 0em;
    margin-block-end: 0em;
    padding-bottom: 30px;
}


.imageContainer {
    width: 100%;
    border-radius: 20px;
    min-width: 250px;
}

.image {
    width: 100%;
    border-radius: 20px;
}

.descriptionContainer {
    padding-top: 30px;
    padding-right: 50px;
}

@media screen and (max-width: 1240px) {
    .container {
        max-width: 700px;
        width: 100%;
        align-self: center;
        margin-top: 0px;
        margin-left: 0px;
        margin-bottom: 50px;
    }


}

@media screen and (max-width: 550px) {
    .description {
        padding-bottom: 20px;
    }

    .container {
        padding: 20px 0px 20px 20px;
        margin-top: 0px;
    }

    .descriptionContainer {
        padding-top: 20px;
        padding-right: 20px;
    }

    .imageContainer {
        max-width: 100%;
        min-width: 100%;
    }


}