.main {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-top: 150px;
  padding-bottom: 100px;
}

.settingsContainer {
  background-color: var(--color-background);
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 auto;
  width: 100%;
  max-width: 1200px;
}

.controlsContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  margin-top: -52px;
}

.formContainer {
  margin: 50px 0;
  width: 100%;
}

.titleContainer {
  display: flex;
  align-items: flex-end;
}

.wrongIcon {
  margin-right: 15px;
  margin-bottom: 3px;
  width: 24px;
  height: 24px;
}

.title,
.titleLanguage {
  font-size: 24px;
  font-family: Raleway;
  font-weight: 700;
  line-height: 110%;
  margin-block-start: 0em;
  margin-block-end: 0em;
}

.languageContainer {
  padding-top: 50px;
}

.titleLanguage {
  padding-bottom: 30px;
}

.blockContainer {
  padding-top: 30px;
}

@media screen and (max-width: 1200px) {
  .container {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media screen and (max-width: 768px) {
  .main {
    padding-bottom: 70px;
  }

  .container {
    padding-left: 34px;
    padding-right: 34px;
  }
}

@media screen and (max-width: 550px) {
  .main {
    padding-top: 95px;
    padding-bottom: 50px;
  }

  .container {
    padding-left: 15px;
    padding-right: 15px;
  }

  .title,
  .titleLanguage {
    font-size: 20px;
  }

  .languageContainer {
    padding-top: 30px;
  }

  .formContainer {
    margin: 30px 0;
  }

  .titleLanguage {
    padding-bottom: 20px;

  }

  .blockContainer {
    padding-top: 20px;
  }

  .wrapper {
    margin-top: 0px;
  }
}