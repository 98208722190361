.container {
  margin-top: 50px;
}

.titleContainer {
  display: flex;
  align-items: flex-end;
}

.wrongIcon {
  width: 24px;
  height: 24px;
  margin-right: 15px;
}

.title {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 26px;
  font-feature-settings: 'pnum' on, 'lnum' on;
}

.displayContainer {
  display: flex;
  align-items: center;
  margin: 20px 0;
  padding: 0 30px;
  width: 100%;
  height: 100px;
  border-radius: 20px;
  background-color: var(--color-white);
}

.number,
.unknown {
  font-family: 'Soyuz Grotesk';
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 53px;
}

.number {
  color: var(--color-purple);
}

.unknown {
  color: var(--color-red);
}

.text {
  margin-top: 15px;
  margin-left: 15px;
  margin-right: 30px;
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  font-feature-settings: 'pnum' on, 'lnum' on;
}

.iconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  width: 34px;
  height: 70px;
  border-radius: 50px;
  background-color: var(--color-background);
}

.icon {
  width: 10px;
  height: 10px;
}


@media screen and (max-width: 930px) {
  .displayContainer {
    height: 115px;
  }

  .number,
  .unknown {
    font-size: 64px;
    line-height: 70px;
  }

  .text {
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 50px;
  }

  .iconContainer {
    min-width: 45px;
    height: 85px;
  }

  .icon {
    width: 15px;
    height: 15px;
  }
}

@media screen and (max-width: 550px) {
  .text {
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 10px;
  }

  .text:last-child {
    margin-right: 0;
  }
}

@media screen and (max-width: 460px) {
  .container {
    margin-top: 30px;
  }

  .wrongIcon {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }

  .title {
    font-size: 20px;
    line-height: 22px;
  }

  .displayContainer {
    margin: 15px 0;
    padding: 0 20px;
    height: 100px;
  }

  .number,
  .unknown {
    font-size: 36px;
    line-height: 40px;
  }

  .text {
    font-size: 14px;
    line-height: 20px;
  }

  .iconContainer {
    min-width: 34px;
    height: 70px;
  }

  .icon {
    width: 10px;
    height: 10px;
  }
}


/* **************** */

.bloksContainer {
  display: flex;
  justify-content: center;
  column-gap: 40px;
  row-gap: 20px;
  flex-wrap: wrap;
  margin: 0 auto;
  padding: 20px 25px;
  border-radius: 16px;
  background-color: var(--color-background);
}

.bloksErrorContainer {
  border: 1px solid var(--color-red);
}

.block {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 10px;
}

.blockRow {
  display: flex;
  align-items: center;
  column-gap: 8px;
}

.blockLabel {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  font-feature-settings: 'pnum' on, 'lnum' on;
}

.blockRow input {
  background-color: var(--color-background);
}

.error {
  margin: 0 auto;
  margin-top: 20px;
}

.bloksContainer, .error {
  width: 100%;
  max-width: 358px;
}

@media screen and (max-width: 320px) {
  .bloksContainer {
    padding: 15px;
    column-gap: 20px;
    row-gap: 20px;
  }

  .error {
    margin-top: 10px;
  }
}
