.text {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 26px;
  text-align: center;
  font-feature-settings: 'pnum' on, 'lnum' on;
}

@media screen and (max-width: 320px) {
  .text {
    font-size: 20px;
    line-height: 22px;
  }
}
