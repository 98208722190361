.container {
  padding: 40px;
  width: 100%;
  max-width: 500px;
  height: auto;
}

.containerCalendar {
  padding: 0;
  max-width: 420px;
}

.title {
  margin-bottom: 30px;
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 26px;
  font-feature-settings: 'pnum' on, 'lnum' on;
  text-align: center;
}

.description {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  font-feature-settings: 'pnum' on, 'lnum' on;
  color: var(--color-light-text);
}

.textCenter {
  text-align: center;
}

.controlContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
}

.cancelBtn,
.confirmBtn {
  width: 200px;
  height: 52px;
  border-radius: 16px;
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  text-transform: uppercase;
  font-feature-settings: 'pnum' on, 'lnum' on;
}

.cancelBtn {
  margin-right: 20px;
  background-color: var(--color-background);
  color: var(--color-purple);
}

.cancelBtn:hover {
  background-color: var(--color-white);
}

.confirmBtn {
  background-color: var(--color-purple);
  color: var(--color-white);
}

.confirmBtn:hover {
  background: linear-gradient(83.01deg, #7460DC 10.92%, #9D8DF1 89.08%);
}

@media screen and (max-width: 570px) {
  .controlContainer {
    flex-direction: column;
  }

  .cancelBtn {
    order: 2;
    margin-right: 0;
    width: 100%;
  }

  .confirmBtn {
    margin-bottom: 10px;
    width: 100%;
  }

}


@media screen and (max-width: 550px) {

  .title {
    font-size: 18px;
    line-height: 25px;
  }

  .description {
    font-size: 12px;
    line-height: 17px;
  }

}

@media screen and (max-width: 430px) {
  .container {
    padding: 30px;
    max-width: 290px;
  }

  .containerCalendar {
    padding: 0;
    max-width: 280px;
  }

  .title {
    font-size: 18px;
    line-height: 25px;
  }

  .description {
    font-size: 12px;
    line-height: 17px;
  }

  .controlContainer {
    flex-direction: column;
  }

  .cancelBtn,
  .confirmBtn {
    font-size: 12px;
    line-height: 17x;
  }
}