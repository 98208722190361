.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 10px;
}

.quantity {
  font-size: 24px;
  font-weight: 400;
  color: var(--color-text);
  padding-bottom: 20px;
}

.caption {
  font-size: 16px;
  font-weight: 500;
  color: var(--color-light-text);
  padding-bottom: 20px;
}

.keyword {
  font-size: 14px;
  font-weight: 500;
  color: var(--color-text);
  padding-left: 20px;
  padding-right: 20px;
  margin-block-start: 0em;
  margin-block-end: 0em;
}

.chips {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

.chipWrapper {
  display: flex;
  height: 40px;
  margin-bottom: 10px;
  flex-direction: row;
  align-items: center;
  background-color: var(--color-background);
  border-radius: 12px;
  margin-right: 10px;
  cursor: pointer;
}

@media screen and (max-width: 550px) {
  .wrapper {
    padding-left: 0px;

  }

  .quantity {
    font-size: 16px;
    font-weight: 400;
  }

  .caption {
    font-size: 12px;
    font-weight: 500;
  }

  .keyword {
    font-size: 12px;
    font-weight: 500;
    padding-left: 10px;
    padding-right: 10px;
  }

  .chipWrapper {
    height: 27px;
    border-radius: 8px;
  }
}