.section {
  padding-bottom: 100px;
  background-color: var(--color-background);
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
  row-gap: 70px;
  column-gap: 30px;
  margin: 0 auto;
  width: 100%;
  max-width: 1200px;
  height: 970px;
}

.wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  width: 100%;
  max-width: 585px;
  padding: 50px 40px 0px 40px;
}

.descriptionContainer {
  padding-top: 30px;
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  font-feature-settings: 'pnum' on, 'lnum' on;
  text-align: start;
}

@media screen and (max-width: 1250px) {
  .section {
    padding-bottom: 70px;
  }

  .container {
    align-items: center;
    row-gap: 30px;
    height: auto;
    flex-wrap: nowrap;
  }
}

@media screen and (max-width: 1200px) {
  .container {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media screen and (max-width: 768px) {
  .container {
    padding-left: 34px;
    padding-right: 34px;
  }

  .wrapper {
    padding: 0;
  }
}

@media screen and (max-width: 420px) {
  .descriptionContainer {
    padding-top: 0px;
  }
}

@media screen and (max-width: 320px) {
  .container {
    row-gap: 20px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .wrapper {
    row-gap: 10px;
  }

  .descriptionContainer {
    font-size: 14px;
    line-height: 20px;
  }
}