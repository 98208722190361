.imageContainer {
  width: 100px;
  height: 100px;
  border-radius: 50px;
  display: flex;
  align-self: center;

  z-index: 100;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 30px;
  align-items: center;
}

.wrapper {

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.imageWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 120px;
  height: 120px;
  border-radius: 60px;
  background-color: white;
  position: absolute;
  top: -100px;
}

.primaryButton {
  padding-bottom: 20px;

}