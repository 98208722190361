.text_page_image_container {
    width: 100%;
}

.text_page_test_result_container {
    padding: 20px 30px 30px 30px;
    background: #e0fff0;
    color: #2eab6c;
    border-radius: 20px;
    margin-top: 50px;
    display: flex;
    justify-content: center;
    text-align: center;
}

.text_page_finishing_test_title {
    font-size: 24px;
    font-weight: 500;
    padding: 0px;
}

.text_page_link {
    text-decoration: underline;
    color: #0270c9 !important;
    font-weight: 500;
}