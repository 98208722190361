.container {
  display: flex;
  column-gap: 30px;
  height: 60px;
}

.icon {
  height: 100%;
}

@media screen and (max-width: 420px) {
  .container {
    column-gap: 20px;
    height: 40px;
  }
}
