.container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding-top: 130px;

}

.fullName {
    font-size: 36px;
    font-weight: 700;
    margin-block-start: 0em;
    margin-block-end: 0em;
}

.wrapper {
    padding-top: 30px;
    padding-bottom: 100px;
    display: flex;
    flex-direction: row;
    position: relative;
}

.image {
    width: 100%;
    max-width: 400px;
    max-height: 400px;
    min-height: 200px;
    aspect-ratio: 1;
    margin-right: 50px;
    border-radius: 30px;
    object-fit: cover;
    object-position: top;
}

.expertInfoContainer {
    display: flex;
    flex-direction: column;
}

.field {
    font-size: 16px;
    font-weight: 500;
    padding-top: 20px;
    color: var(--color-light-text);
    font-family: 'Raleway';
    max-width: 474px;
    line-height: 24px;
    margin-bottom: 30px;

}

.field {
    margin-block-start: 0em;
}


.expertTitle,
.contentTitle,
.name {
    margin-block-start: 0em;
    margin-block-end: 0em;
}

.containerIcons {
    padding-bottom: 70px;
}

.expertDescriptionWrapper {
    width: 100%;
    display: flex;
    justify-content: center;
}

.expertDescriptionContainer {
    width: 100%;
    max-width: 790px;

}

.expertTitle {
    font-size: 36px;
    font-weight: 700;
    padding-bottom: 50px;
}

.contentTitle {
    padding-top: 100px;
    padding-bottom: 20px;
    padding-left: 100px;
    line-height: 43px;
    font-family: 'Raleway';
    font-size: 36px;
    margin-block-start: 0em;
    margin-block-end: 0em;
}

.name {
    max-width: 445px;
    font-size: 36px;
}

.nameContainer {
    max-width: 475px;
    min-width: 290px;
}

.background {
    background-color: var(--color-background);
}

@media screen and (max-width: 768px) {
    .wrapper {
        padding-left: 0px;
        padding-right: 0px;
        padding-bottom: 70px;
    }

    .container {
        padding-left: 30px;
        padding-right: 30px;
        padding-top: 134px;
    }

    .image {
        max-width: 250px;
        max-height: 250px;
    }


    .expertTitle {
        font-size: 36px;
        font-weight: 700;
        padding-bottom: 30px;
    }

    .contentTitle {
        padding-top: 70px;
        padding-bottom: 0px;
        padding-left: 0px;
    }

    .containerIcons {
        padding-bottom: 50px;
    }

    .contentTitle {
        margin-bottom: -40px;
    }

    .field {
        margin-bottom: 30;
    }

}


@media screen and (max-width: 640px) {

    .container {
        padding-top: 95px;
    }

    .wrapper {

        display: flex;
        flex-direction: column;
        position: relative;
        padding-bottom: 50px;
    }

    .containerIcons {
        padding-bottom: 30px;
    }

    .image {
        margin-top: -10px;
        margin-bottom: 20px;
        max-width: 200px;
        max-height: 200px;
        align-self: center;
        margin-right: 0px;
        min-width: 200px;
    }

    .container {
        padding-left: 15px;
        padding-right: 15px;
    }

    .name {
        max-width: 445px;
        font-size: 28px;
        line-height: 33px;
        font-weight: 700;
    }

    .field {
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        padding-bottom: 0px;
        margin-bottom: 20px;
    }

    .containerIcons {
        display: flex;
        flex-direction: row;
    }

    .contentTitle {
        margin-bottom: 0px;
    }

    .fullName {
        font-size: 28px;
        font-weight: 700;
        margin-block-start: 0em;
        margin-block-end: 0em;
    }
}