.container {
    position: absolute;
    right: 20px;
    display: flex;
    flex-direction: row;
    white-space: nowrap;
    justify-content: center;
    align-items: center;
}

.title {
    font-size: 20px;
    font-weight: 500;
    padding-left: 20px;
}

@media screen and (max-width: 550px) {
    .container {
        padding-bottom: 30px;
    }
}