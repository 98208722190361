.container {
    background-color: var(--color-background);
}

.studyScreen {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding-top: 148px;
}

.studyScreenWrapper {
    padding-left: 100px;
    padding-right: 100px;
}

@media screen and (max-width: 768px) {
    .studyScreenWrapper {
        padding-left: 0px;
        padding-right: 0px;
    }

    .studyScreen {
        padding-left: 30px;
        padding-right: 30px;
        padding-top: 152px;
        padding-top: 148px;
    }
}


@media screen and (max-width: 550px) {
    .studyScreen {
        padding-left: 15px;
        padding-right: 15px;
        padding-top: 85px;
    }
}