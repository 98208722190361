.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  margin-bottom: 49px;
}

.titleContainer {
  width: 100%;

  text-align: center;

}


@media screen and (max-width: 768px) {}

@media screen and (max-width: 550px) {
  .titleContainer {
    margin-top: 100px;
  }

  .container {
    margin-bottom: 30px;
    height: 100px;
    justify-content: flex-end;
  }
}