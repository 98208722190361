.section {
  position: relative;
  padding-top: 100px;
  background-color: var(--color-background);
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  width: 100%;
  max-width: 1200px;
}

.header {
  display: flex;
  column-gap: 100px;
  width: 100%;
}

.titleContainer {
  display: flex;
  flex-direction: column;
  row-gap: 70px;
}

.certificate {
  align-self: center;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 30px;
  width: 100%;
  max-width: 800px;
}

.certificateIcon {
  width: 100px;
  height: 100px;
}

.certificateDescription {
  position: relative;
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 26px;
  font-feature-settings: 'pnum' on, 'lnum' on;
}

.arrow {
  position: absolute;
  top: -100px;
  left: 0;
  width: 85px;
  height: 85px;
  transform: rotate(30deg);
}

.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 210px;
}

@media screen and (max-width: 1230px) {
  .arrow {
    top: -170px;
    left: -50px;
    width: 140px;
    height: 140px;
    transform: rotate(0deg);
  }
}

@media screen and (max-width: 1200px) {
  .container {
    padding-left: 20px;
    padding-right: 20px;
  }

  .header {
    column-gap: 50px;
  }

  .icon {
    position: absolute;
    right: 20px;
    top: 280px;
  }
}

@media screen and (max-width: 1080px) {
  .section {
    padding-top: 70px;
  }
}

@media screen and (max-width: 780px) {
  .icon {
    width: 200px;
    height: 200px;
  }
}

@media screen and (max-width: 768px) {
  .container {
    column-gap: 10px;
    padding-left: 34px;
    padding-right: 34px;
  }

  .titleContainer {
    row-gap: 50px;
  }

  .footer {
    height: 200px;
  }
}

@media screen and (max-width: 650px) {
  .icon {
    display: none;
  }

  .certificateIcon {
    width: 80px;
    height: 80px;
  }

  .arrow {
    display: none;
  }
}

@media screen and (max-width: 430px) {
  .certificate {
    flex-direction: column;
    row-gap: 10px;
  }

  .certificateDescription {
    font-size: 16px;
    line-height: 20px;
    text-align: center;
  }
}

@media screen and (max-width: 320px) {
  .section {
    padding-top: 50px;
  }

  .container {
    padding-left: 10px;
    padding-right: 10px;
  }

  .titleContainer {
    row-gap: 20px;
  }

  .footer {
    height: 140px;
  }
}