.container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding-top: 130px;
    padding-bottom: 50px;
}

.blocksWrapper {
    padding-left: 100px;
    padding-right: 100px;
}

.background {
    background-color: var(--color-background);
}

@media screen and (max-width: 768px) {
    .blocksWrapper {
        padding-left: 0px;
        padding-right: 0px;
    }

    .container {
        padding-left: 30px;
        padding-right: 30px;
        padding-top: 125px;
    }
}


@media screen and (max-width: 550px) {

    .container {
        padding-top: 95px;
    }

    .container {
        padding-left: 15px;
        padding-right: 15px;

    }
}