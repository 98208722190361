.container {
    background-color: var(--color-white);
    border-radius: 20px;
    width: calc(100% - 460px);
    height: auto;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    justify-content: start;
    color: var(--color-text);
}

.chevron svg {
    width: 100%;
}

.title {
    font-size: 20px;
    font-family: 'Raleway';
    font-weight: 500;
    line-height: 26px;
    margin-block-start: 0em;
    margin-block-end: 0em;
}

.icon {
    display: flex;
    justify-content: center;
    position: absolute;
    height: 100%;
    width: 100%;
    padding-top: 21%;
}

.icon svg {
    width: 11vw;
}

@media screen and (max-width: 1240px) {
    .container {
        width: 100%;
        max-width: 220px;
        min-width: 90px;
        margin: 10px;
        display: flex;
        justify-content: space-between;
    }


    .chevron svg {
        height: auto;
    }
}

@media screen and (max-width: 550px) {
    .container {
        width: 100%;
        margin: 5px;
        height: auto;
        display: flex;
        justify-content: start;
        padding: 5px;
    }

    .chevron svg {
        height: auto;
        display: flex;
        align-self: start;
    }



    .title {
        font-size: 16px;
    }
}


@media screen and (max-width: 460px) {

    .container {
        height: auto;
        border-radius: 10px;
        display: flex;
        justify-content: space-between;
    }
}