.phone {
  position: absolute;
  left: -20px;
  right: 0;
  top: 0;
  bottom: 0;
  opacity: 0;
  transition: all 0.5s ease-in;
  width: 330px;
  height: 630px;
}

.show {
  opacity: 1;
}

@media screen and (max-width: 440px) {
  .phone {
    margin-top: 10px;
    height: 498px;
    width: 270px;
  }
}