.section {
  padding-top: 90px;
  background: linear-gradient(83.01deg, #7460DC 10.92%, #9D8DF1 89.08%);
}

.container {
  display: flex;
  justify-content: center;
  column-gap: 40px;
  margin: 0 auto;
  width: 100%;
  max-width: 1000px;
}

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-width: 600px;
  padding-top: 30px;
}

.descriptionContainer {
  padding-top: 30px;
  padding-bottom: 40px;
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  font-feature-settings: 'pnum' on, 'lnum' on;
  color: var(--color-white);
  text-align: start;
}

.linksContainer {
  display: flex;
  align-items: center;
  padding-top: 30px;
  column-gap: 30px;
}

.imageContainer {
  position: relative;
  width: 100%;
  max-width: 360px;
  height: 510px;
}

.image {
  z-index: 1000;
  width: 520px;
  height: 680px;
  position: absolute;
  top: 20px;
  right: -30px;
}

@media screen and (max-width: 1200px) {
  .container {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media screen and (max-width: 1080px) {
  .image {
    height: 580px;
    width: auto;
    top: 0;
    right: 0;
  }
}

@media screen and (max-width: 1024px) {
  .image {
    right: 0;
  }
}

@media screen and (max-width: 768px) {
  .section {
    padding-top: 114px;
  }

  .container {
    column-gap: 10px;
    padding-left: 34px;
    padding-right: 34px;
  }

  .descriptionContainer {
    padding-top: 20px;
    padding-bottom: 101px;
  }

  .linksContainer {
    column-gap: 20px;
  }
}

@media screen and (max-width: 760px) {
  .container {
    margin-bottom: -20px;
    background-image: url(../../../../assets/icons/home/welcomeSectionSmall.svg);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 100% 140px;
  }

  .image {
    display: none;
  }
}

@media screen and (max-width: 440px) {
  .section {
    padding-top: 100px;
  }

  .container {
    height: 500px;
    margin-bottom: 0px;
    background-position: 100% 180px;
  }

  .wrapper {
    justify-content: flex-start;
  }

  .descriptionContainer {
    padding-top: 10px;
    padding-bottom: 40px;
    font-size: 14px;
    line-height: 20px;
  }

  .linksContainer {
    column-gap: 10px;
  }
}

@media screen and (max-width: 320px) {
  .container {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media screen and (max-width: 310px) {
  .linksContainer {
    flex-direction: column;
    align-items: flex-start;
    row-gap: 10px;
  }
}