.container {
  flex-grow: 1;
  height: 52px;
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  text-transform: uppercase;
  font-feature-settings: 'pnum' on, 'lnum' on;
  color: var(--color-white);
  background-color: var(--color-purple);
  border-radius: 16px;
  transition: all 0.3s ease;
  box-shadow: 0px 8px 25px rgba(157, 141, 241, 0.4);
}

.container:hover {
  background-color: var(--color-purple-hover);
}

@media screen and (max-width: 320px) {
  .container {
    height: 47px;
    font-size: 12px;
    line-height: 17px;
  }
}

.containerDisabled {
  flex-grow: 1;
  height: 52px;
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  text-transform: uppercase;
  font-feature-settings: 'pnum' on, 'lnum' on;
  color: var(--color-white);
  background-color: var(--color-purple);
  border-radius: 16px;
  transition: all 0.3s ease;
  box-shadow: 0px 8px 25px rgba(157, 141, 241, 0.4);
  opacity: 0.5;
  background-color: '#9D8DF1'
}