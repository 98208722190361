.container {
  background-color: var(--color-white);
  border-radius: 16px;
  padding-top: 17px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 16px;
  display: flex;
  flex-direction: row;
  justify-content: start;
  text-align: start;
  align-items: start;

}

.textContainer {
  padding-left: 16px;

}

.forgotPassword {
  display: flex;
  align-items: start;
  align-self: start;
  margin-block-start: 0em;
  font-size: 14px;
  font-weight: 500;
}

.timer {
  color: #9D8DF1;
  font-size: 16px;
  font-weight: 700;
  margin-block-start: 0em;
  margin-block-end: 0em;
  text-decoration: underline;
}

@media (max-width: 380px) {
  .forgotPassword {
    font-size: 12px;
    font-weight: 500;
  }

  .timer {
    font-size: 12px;
    font-weight: 700;
  }

  .container {
    padding-top: 16px;
  }

  .icon {
    width: 16;
  }

  .textContainer {
    padding-left: 10px;
  }
}