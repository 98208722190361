.section {
  position: relative;
  background-color: var(--color-background);
}

.anchor {
  position: absolute;
  top: -130px;
}

.container {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
  max-width: 1200px;
}

.wrapper {
  width: 100%;
  margin-top: 50px;
  margin-bottom: 100px;
  padding: 30px 50px;
  border-radius: 40px;
  background-color: var(--color-white);
}

.container_1600 {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  row-gap: 30px;
  column-gap: 40px;
}

.container_768,
.container_320 {
  display: none;
}

.icon {
  height: 80px;
  width: auto;
}

@media screen and (max-width: 1250px) {
  .container {
    padding-left: 20px;
    padding-right: 20px;
  }

  .wrapper {
    padding: 30px 30px;
  }

  .container_1600 {
    column-gap: 20px;
  }
}

@media screen and (max-width: 1140px) {
  .wrapper {
    margin: 50px auto 70px auto;
    padding: 30px 50px;
    max-width: 700px;
  }

  .container_1600 {
    display: none;
  }

  .container_768 {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    row-gap: 30px;
    column-gap: 19px;
  }

  .icon {
    height: 70px;
  }
}

@media screen and (max-width: 768px) {
  .container {
    padding-left: 34px;
    padding-right: 34px;
  }
}

@media screen and (max-width: 767px) {
  .wrapper {
    padding: 30px 30px;
  }

  .container_768 {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    row-gap: 30px;
    column-gap: 10px;
  }
}


@media screen and (max-width: 682px) {
  .wrapper {
    margin: 30px auto 50px auto;
    max-width: 290px;
  }

  .container_768 {
    display: none;
  }

  .container_320 {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    row-gap: 20px;
    column-gap: 10px;
  }

  .icon {
    height: 50px;
  }
}

@media screen and (max-width: 480px) {  
  .container {
    padding-left: 15px;
    padding-right: 15px;
  }
}
