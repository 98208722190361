.container,
.containerFlat {
    display: flex;
    flex-direction: row;
    width: 100%;
    background-color: var(--color-background);
    border-radius: 16px;
    justify-content: center;
    height: 52px;
    align-items: center;
    color: var(--color-purple);

}

.containerFlat {
    background-color: var(--color-purple);
    color: var(--color-white);
    margin-left: 20px;
}

.title {
    font-size: 14px;
    font-weight: 700;
    font-family: 'Raleway';
    line-height: 17px;
    text-transform: uppercase;

}

@media screen and (max-width: 550px) {
    .title {
        font-size: 12px;
        font-weight: 700;
    }

    .containerFlat {
        margin-top: 10px;
        margin-left: 0px;
    }
}