.emptyWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: var(--color-background);
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 186px;
  padding-right: 186px;
  text-align: center;

}

.title {
  font-weight: 700;
  font-size: 36px;
  padding-bottom: 8px;
  color: var(--color-text)
}

.subtitle,
.title,
.extraInfo {
  margin-block-start: 0em;
  margin-block-end: 0em;
  font-size: 'Raleway';
}

.subtitle,
.extraInfo {
  font-weight: 500;
  font-size: 16px;
  padding-bottom: 10px;
  color: var(--color-light-text);
}

.extraInfo {
  padding-top: 10px;
}

.subtitle .extraInfo {
  font-size: 16px;
  font-weight: 500;
  color: var(--color-light-text)
}

.infoContainer {
  padding-top: 10px;
  padding-bottom: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 600px;
  padding-top: 30px;
}


@media screen and (max-width: 768px) {

  .container {
    padding-left: 0px;
    padding-right: 0px;
    margin-top: 20px;
  }

  .infoContainer {
    max-width: 100%;
  }

  .infoContainer {
    padding-bottom: 30px;
  }
}

@media screen and (max-width: 440px) {

  .icon svg {
    max-width: 100px;
    max-height: 100px;
  }

  .title {
    font-weight: 700;
    font-size: 24px;
  }

  .subtitle,
  .extraInfo {
    font-weight: 500;
    font-size: 12px;
  }

  .container {
    margin-top: 0px;
  }
}