.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 20px;
}

@media screen and (max-width: 930px) {
  .container {
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 20px;
  }
}

@media screen and (max-width: 320px) {
  .container {
    gap: 10px;
  }
}
