.datePickerInput {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  margin: 30px 0;
  height: 52px;
  border-radius: 16px;
  background-color: var(--color-background);
}

.icon {
  width: 12px;
  height: 12px;
}

.periodPregnancy  {
  margin-bottom: 30px;
}

.title {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 31px;
  font-feature-settings: 'pnum' on, 'lnum' on;
}

.displayPeriodPregnancy {
  display: flex;
  align-items: center;
  width: 100%;
}

.number {
  font-family: 'Soyuz Grotesk';
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 53px;
  color: var(--color-purple);
}

.text {
  margin-top: 15px;
  margin-left: 15px;
  margin-right: 30px;
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  font-feature-settings: 'pnum' on, 'lnum' on;
}

.gap {
  height: 20px;
}

@media screen and (max-width: 930px) {
  .number {
    font-size: 64px;
    line-height: 70px;
  }

  .text {
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 50px;
  }
}

@media screen and (max-width: 550px) {
  .text {
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 10px;
  }

  .text:last-child {
    margin-right: 0;
  }
}

@media screen and (max-width: 460px) {
  .title {
    font-size: 16px;
    line-height: 17px;
  }

  .displayPeriodPregnancy {

  }

  .number {
    font-size: 36px;
    line-height: 40px;
  }

  .text {
    font-size: 14px;
    line-height: 20px;
  }

  .gap {
    height: 15px;
  }
}
