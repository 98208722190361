.container {
  display: flex;
  align-items: center;
  column-gap: 30px;
  width: 100%;
  padding-top: 82px;
  padding-bottom: 90px;
}

@media screen and (max-width: 1230px) {
  .container {
    flex-direction: column;
    row-gap: 30px;
  }
}

@media screen and (max-width: 768px) {
  .container {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

@media screen and (max-width: 320px) {
  .container {
    padding-top: 30px;
    padding-bottom: 30px;
    row-gap: 10px;
  }
}
