.videoGroupWrapper {
    display: flex;
    flex-direction: column;
}

.lessonsTitle {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    margin-left: 20px;
}

.lessons {
    font-size: 24px;
    font-weight: 700;
    padding-left: 15px;
    margin-block-start: 0em;
    margin-block-end: 0em;
}

@media screen and (max-width: 1200px) {

    .videoGroupWrapper {
        padding-top: 70px;
        width: 100%;
        max-width: 770px;
    }

    .lessonsTitle {
        margin-left: 0px;
        margin-bottom: 30px;
    }
}

@media screen and (min-width: 1201px) {
    .videoGroupWrapper {
        margin-left: 32px;
    }
}

@media screen and (max-width: 440px) {

    .videoGroupWrapper {
        padding-top: 50px;
        margin-right: 0;
    }

    .lessonsTitle {
        margin-left: 0px;
        margin-bottom: 20px;
    }

    .lessons {
        font-size: 20px;
        font-weight: 700;

    }
}