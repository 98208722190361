.h_one_desc{
	font-size: 20.8px;
    font-weight: 350;
    text-align: center;
    margin-top: 33px;
}

.h_one_desc_two{
    font-size: 20.8px;
    font-weight: 500;
    text-align: center;
    margin-top: 62px;
}