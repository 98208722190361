.favoriteContainer {
  display: flex;
  flex-direction: row;
  align-content: center;

}


@media screen and (max-width: 550px) {
  .favoriteContainer {
    display: flex;
    align-self: center;
    justify-content: space-between;
    width: 100%;
  }

  .chipContainer {
    width: calc(50% - 8px);
  }
}