.pixelated {
    image-rendering: pixelated;
}

.expertsList {
    position: relative;
    max-height: 300px;
    overflow: hidden;
}

.expertsList.show-all {
    max-height: none;
}

.overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100px;
    width: 100%;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.9) 60%, #f3f3f5 100%);
    box-sizing: border-box;
}

.expertsAll {
    margin-left: 24px;
    padding-left: 24px;
}

.header,
.course-info,
.info-blocks,
.course-host,
.course-conclusion,
.sponsors {
    margin-bottom: 20px;
    font-family: 'Raleway, sans-serif';
}

.header h1,
.experts h2,
.course-info h3 {
    margin: 0 0 0px;
}

.experts ul,
.course-info ul,
.info-blocks,
.sponsors {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.experts ul li,
.info-blocks .info-block {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.сourseSubtitle {
    text-align: center;
    margin-top: -20px;
}

.сourse {
    font-family: 'Soyuz Grotesk';
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 58px;
    letter-spacing: 0.03em;
    text-align: center;
    margin-top: -10px;
    padding-bottom: 0;
    color: black;
}

.experts ul li img,
.info-blocks .info-block img {
    width: 50px;
    height: 50px;
    margin-right: 10px;
}

.show-all-experts {
    background: #ff6f61;
    color: #fff;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px;
    border-radius: 5px;
}

.course-image {
    width: 100%;
    height: auto;
    margin-bottom: -5px;
}

.sponsors img {
    width: 50px;
    height: auto;
    margin-right: 10px;
}

.mzr_first {
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;

    margin-top: 150px;
    margin-bottom: 100px;
}

.mzr_first article {
    max-width: 600px;
    width: 100%;
}

.course-intro {
    text-align: center;
    margin: 20px 0;
}

.course-intro img {
    max-width: 100%;
    border-radius: 10px;
}

.experts {
    padding: 20px 0;
}

.experts .title {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-direction: column;
    text-align: center;
}

.experts .title p {
    font-size: 14px;
    font-weight: 400;
}

.experts ul li {
    display: flex;
    align-items: center;
    margin: 10px 0;
}

.experts ul li img {
    width: 60px;
    height: 60px;
}

.experts ul li span {
    display: flex;
    flex-direction: column;
}

.experts ul li span .name {
    font-size: 16px;
    font-weight: 700;
}

.custom-button {
    width: 360px;
    height: 52px;
    border-radius: 16px;
    background-color: #F69620;
    color: #fff;
    border: none;
    padding: 10px 20px;
    display: block;
    margin: 0px auto;
    font-family: 'Raleway';
    font-weight: 700;
    font-size: 20px;
    line-height: 19.6px;
    text-align: center;
}

.course-host {
    padding: 20px 0;
    text-align: center;
    font-family: 'Raleway, sans-serif';
}

.course-host-title {
    font-family: 'Raleway, sans-serif';
    font-weight: 700;
    font-size: 30px;
}

.course-host-description {
    font-family: 'Raleway, sans-serif';
    font-weight: 400;
    font-size: 16px;
    color: black;
}

.video-container {
    margin: 20px auto;
    height: 168px;
    border-radius: 9px;
    overflow: hidden;
    display: block;
    width: 360px;
    background-color: black;
}

.video-frame {
    width: 360px;
    height: 100%;
    border: none;
    display: block;
}

.video-container {
    position: relative;
    width: calc(100% - 22px);
    /* Ширина с учетом отступов */
    height: 0;
    padding-bottom: 56.25%;
    /* Соотношение сторон 16:9 */
    margin: 0 10px;
    /* Отступы 10 пикселей по бокам */
    overflow: hidden;
}

.video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
}