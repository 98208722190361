.removalDescription {
    font-size: 16px;
    font-weight: 500;
    font-family: 'Raleway';
    display: flex;
    text-align: center;
    margin-block-start: 0em;
    margin-block-end: 0em;
    color: var(--color-light-text);
}

.buttonsContainer {
    display: flex;
    flex-direction: row;
    padding-top: 30px;
}

.inputWrapper {
    margin-top: 10px;
}

.inputContainer {
    background-color: var(--color-background);
}

@media screen and (max-width: 550px) {
    .removalDescription {
        font-size: 12px;
        line-height: 18px;
    }

    .buttonsContainer {
        display: flex;
        flex-direction: column;
    }

    .inputWrapper {
        margin-top: 0px;
    }
}