.container {
  width: 62px;
  height: 48px;
}


@media screen and (max-width: 550px) {
  .container {
    width: 52px;
    height: 38px;
  }
}