.title {
    font-size: 20px;
    font-weight: 500;
    padding-left: 20px;
    color: var(--color-text);
    margin-block-start: 0em;
    margin-block-end: 0em;
}

@media screen and (max-width: 550px) {
    .title {
        font-size: 14px;
        font-weight: 500;
    }
}