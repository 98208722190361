.container {
  height: 52px;
  width: fit-content;
  border-radius: 16px;
  background-color: var(--color-purple);
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  width: fit-content;
  padding: 0px;
}

.studyContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: start;
  align-content: start;
}

.logo {
  height: 50px;
  padding-left: 30px;
}

.container:hover {
  background: linear-gradient(83.01deg, #7460DC 10.92%, #9D8DF1 89.08%);
}

.title {
  font-size: 14px;
  font-weight: 700;
  color: var(--color-white);
  text-transform: uppercase;
  padding-right: 40px;
  padding-left: 40px;
}

@media screen and (max-width: 550px) {

  .container {
    height: 47px;
  }

  .title {
    font-size: 14px;
    font-weight: 700;
    color: var(--color-white);
    text-transform: uppercase;
    padding-right: 30px;
    padding-left: 30px;
  }

  .logo {
    height: 40px;
    padding-left: 30px;
  }

  .studyContainer {
    max-width: 100%;
    flex: 1;
    display: flex;
    justify-content: space-between;
  }
}

@media screen and (max-width: 360px) {
  .logo {
    height: 36px;
    padding-left: 30px;
  }

}