.expertWrapper {
  display: flex;
  flex-direction: row;
  margin-top: 30px;
}

.expertContainer {
  width: 100%;
  min-width: 200px;
  padding-left: 20px;
  display: flex;
  flex-direction: column;
}

.name {
  font-size: 24px;
  font-weight: 600;
  color: var(--color-text);
  margin-block-start: 0em;
  margin-block-end: 0em;
}

.workPlace {
  margin-block-start: 0em;
  margin-block-end: 0em;
  color: var(--color-light-text);
  font-size: 12;
  font-weight: 500;
  margin-top: 5px;
}

.job {
  margin-block-start: 0em;
  margin-block-end: 0em;
  font-size: 14px;
  color: var(--color-text);
  font-weight: 600;
  line-height: 20px;
  text-transform: uppercase;
}

.imgExpert {
  width: 150px;
  height: 150px;
  border-radius: 10px;
  object-fit: cover;
  object-position: top;
}

@media screen and (max-width: 768px) {}


@media screen and (max-width: 550px) {
  .expertContainer {
    padding-left: 15px;
  }

  .expertWrapper {
    margin-top: 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    text-align: center;
    align-self: center;
  }

  .imgExpert {
    margin-bottom: 15px;
  }

  .name {
    margin-bottom: 5px;
  }

}