.studyScreenBox {
    position: relative;
    height: 200px;
    padding: 30px;
    max-width: 320px;
    min-width: 280px;
    flex: 1 1 0;
    width: 0;
    margin-bottom: 20px;
    background-color: var(--color-white);
    color: var(--color-text);
    outline: 0px solid var(--color-purple);
    border-radius: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-left: 10px;
    margin-right: 10px;
    transition: 0.1s;
}

.studyContainer {
    padding-top: 100px;
}

.studyScreenBox:hover {
    outline: 2px solid var(--color-purple);
}

.studyScreenBox::before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    /* don't forget the  */
    transition: 0.1s;
}

.studyScreenBox:hover::before {
    width: calc(100% + 1px);
    height: calc(100% + 1px);
    transition: 0.1s;
}

.studyScreenBoxesContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin-left: -10px;
    margin-right: -10px;
    margin-top: 50px;
    padding-bottom: 70px;
}

.title {
    font-size: 24px;
    font-weight: 600;
    margin-block-start: 0em;
    margin-block-end: 0em;
    position: absolute;
    max-width: 230px;
    min-width: 200px;
}

.iconContainer {
    display: flex;
    align-self: flex-end;
}

.titleContainer {
    width: 66%;
}

.iconContainer svg {
    width: 10vw;
    height: auto;
    min-width: 40px;
    max-width: 80px;
}

@media screen and (max-width: 768px) {
    .studyScreenBox {
        width: 335px;
        height: 180px;
        margin-bottom: 30px;
        margin-left: 15px;
        margin-right: 15px;
    }

    .studyScreenBoxesContainer {
        margin-left: -15px;
        margin-right: -15px;
        padding-bottom: 43px;
    }

    .studyContainer {
        padding-top: 70px;
    }
}


@media screen and (max-width: 550px) {
    .studyScreenBox {
        height: 70px;
        width: 100%;
        margin-bottom: 10px;
        padding-left: 20px;
        padding-top: 14px;
        padding-bottom: 14px;
        padding-right: 16px;
        display: flex;
        align-items: center;
        margin-left: 0px;
        margin-right: 0px;
    }

    .title {
        font-size: 16px;
        font-weight: 500;
        padding-right: 9px;
        position: static;
        max-width: 230px;
        min-width: 200px;
    }

    .studyScreenBoxesContainer {
        margin-top: 30px;
        margin-left: 0px;
        margin-right: 0px;
    }

    .studyContainer {
        padding-top: 50px;
    }
}