.progressContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 15px;
}

.mainLine {
    background-color: var(--color-background);
    border-radius: 30px;
    margin-bottom: 35px;
    height: 30px;
    padding: 5px;
    display: flex;
    align-items: center;
}

.secondLine {
    height: 20px;
    background-color: var(--color-purple);
    border-radius: 30px;
    background-color: #9D8DF1;
}

.title {
    color: var(--color-light-text);
    font-size: 20px;
    font-weight: 500;
}

.progress {
    color: var(--color-purple);
    font-size: 20px;
    font-weight: 800;
}

@media screen and (max-width: 768px) {
    .mainLine {
        background-color: var(--color-background);
        height: 20px;
        border-radius: 30px;
        margin-bottom: 35px;
    }

    .secondLine {
        height: 15px;
    }
}

@media screen and (max-width: 320px) {
    .container {
        left: 15px;
        right: 15px;
    }
}

@media screen and (max-width: 550px) {
    .progressContainer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-bottom: 10px;
    }

    .title {
        color: var(--color-light-text);
        font-size: 12px;
        font-weight: 500;
        margin-top: 0;
        margin-bottom: 10px;
    }

    .progress {
        color: var(--color-purple);
        font-size: 12px;
        font-weight: 800;
        margin-top: 0;
        margin-bottom: 10px;
    }

    .mainLine {
        background-color: var(--color-background);
        border-radius: 15px;
        margin-bottom: 15px;
        height: 15px;
        padding: 2px;
        display: flex;
        align-items: center;
    }
}