
header .fixed-header{
    background-color: #fff;
}

.main-header {
    position: fixed;
    top: 20px;
    width: 1200px;
    left: 0;
    right: 0;
    border-radius: 80px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: transparent;
    transition: 0.5s;
    padding: 15px 40px;
}

.scrolled {
    background-color: white;
}
.header-logo{
    margin-right: 30px;
}

.header-left, .header-right{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.header-s-networks{
    display: inline-block;
    margin: 0 5px;
}

.main-language-switcher{
    margin-left: 15px;
    position: relative;
    overflow: hidden;
    z-index: 3;
}

.main-language-switcher:hover{
    overflow: visible;
}

.language-switcher-selected{
    font-weight: 700;
    font-size: 14px;
    text-transform: uppercase;
    color: #fff;
    position: relative;
    padding: 10px 40px 10px 20px;
    cursor: pointer;
    transition: 0.5s;
}

.fixed-header .language-switcher-selected{
    color: #3C3C3B;
}

.language-switcher-selected::after{
    content: "";
    display: block;
    background: url("../../assets/icons/language-switcher-white.svg") no-repeat;
    width: 16px;
    height: 16px;
    position: absolute;
    right: 20px;
    top: 0;
    bottom: 0;
    margin: auto;
    transition: 0.5s;
}

.fixed-header .language-switcher-selected::after{
    background: url("../../assets/icons/language-switcher.svg") no-repeat;
}

.main-language-switcher:hover .language-switcher-selected::after{
    transform: rotate(180deg);
}

.languages{
    transition: 0.5s;
    z-index: -1;
    position: absolute;
    top: 36px;
    left: -33px;
    opacity: 0;
    background: #FFFFFF;
    box-shadow: -8px 0px 10px rgba(157, 141, 241, 0.1), 8px 8px 10px rgba(157, 141, 241, 0.1);
    border-radius: 16px;
}

.main-language-switcher:hover .languages{
    opacity: 1;
    z-index: 0;
}

.languages a{
    border-bottom: 1px solid #E8E3FF;
    font-weight: 500;
    font-size: 14px;
    text-transform: uppercase;
    color: #3C3C3B;
    padding: 17.5px 39.5px;
    display: block;
}

.languages a:hover{
    color: #9D8DF1;
}

.languages a:last-child{
    border-bottom: none;
}

.header-join{
    font-weight: 700px;
    font-size: 14px;
    text-transform: uppercase;
    color: #9D8DF1; 
    background-color: #fff;
    box-shadow: 0px 8px 25px rgba(157, 141, 241, 0.4);
    border-radius: 16px;
    padding: 18px 39.7px;
    margin-left: 20px;
    -webkit-backface-visibility: hidden;
    z-index: 1;
    position: relative;
}

.header-join-fixed{
    font-weight: 700;
    font-size: 14px;
    text-transform: uppercase;
    box-shadow: 0px 8px 25px rgba(157, 141, 241, 0.4);
    padding: 18px 39.7px;
    margin-left: 20px;
    -webkit-backface-visibility: hidden;
    z-index: 1;
    position: relative;
    color: #FFFFFF; 
    background-color: #9D8DF1;
    border-radius: 16px;
}


.header-join:after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 16px;
    background: linear-gradient(85.33deg, #E8E3FF 0%, #FFFFFF 100%);
    transition: opacity 0.5s ease-out;
    z-index: 2;
    opacity: 0;
}

.header-join:hover:after {
    opacity: 1;
}

.header-join span {
    position: relative;
    color: #9D8DF1;
    z-index: 3;
    transition: 0.5s;
}

.fixed-header .header-join{
    color: #FFFFFF; 
    background: #9D8DF1;
    border-radius: 16px;
}

.fixed-header .header-join:after {
    background: linear-gradient(83.01deg, #7460DC 10.92%, #9D8DF1 89.08%);
}

.fixed-header .header-join span {
    color: #fff;
}