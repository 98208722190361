.buttonsContainer {
    display: flex;
    flex-direction: row;
    padding-top: 30px;
}

@media screen and (max-width: 550px) {

    .buttonsContainer {
        display: flex;
        flex-direction: column;
    }
}