.containerActive {
  background-color: var(--color-white);
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 350px;
  border-radius: 20px;
  margin-bottom: 10px;
  padding: 10px;
  margin-left: 2px;
  outline: 1px solid var(--color-purple);
}

.container {
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 350px;
  border-radius: 20px;
  margin-bottom: 10px;
  padding: 10px;
  margin-left: 18px;
  outline: 0px solid var(--color-purple);
  transition: 0.1s;
}

.container:hover {
  outline: 1px solid var(--color-purple);
}

.container::before {

  transition: 0.1s;
}

.container:hover::before {
  width: calc(100% + 1px);
  height: calc(100% + 1px);
  transition: 0.1s;
}

.image {
  min-width: 150px;
  height: 85px;
  border-radius: 15px;
  margin-right: 20px;
  object-fit: cover;
  object-position: top;
}

.descriptionContainer {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.description,
.title,
.description {
  margin-block-start: 0em;
  margin-block-end: 0em;
  font-family: 'Raleway';

}

.description {
  font-size: 14px;
  font-weight: 500;
  color: var(--color-light-text);
  margin-top: 5px;
}

.title {
  font-size: 20px;
  line-height: 26px;
  font-weight: 600;
  color: var(--color-text);
}

.contentLesson {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 1200px) {
  .containerActive {
    max-width: 100%;
    margin-left: -15px;
  }

  .container {
    max-width: 100%;
    margin-left: 0px;
  }

  .lessonIcon {
    margin-right: 30px;
    margin-left: -20px;
  }

  .descriptionContainer {
    margin-top: 0px;
  }
}


@media screen and (max-width: 440px) {
  .image {
    width: 20vw;
    max-width: 150px;
    min-width: 100px;
    height: 56px;
    border-radius: 15px;
    margin-right: 10px;
    object-fit: cover;
    object-position: top;
  }

  .containerActive {
    margin-left: 0px;
    border-radius: 15px;

  }

  .container {
    max-width: 100%;
  }

  .description {
    font-size: 12px;
    font-weight: 500;
    color: var(--color-light-text);
  }

  .title {
    font-size: 16px;
    font-weight: 500;
    color: var(--color-text);
  }

  .lessonIcon {
    margin-right: 5px;
    margin-left: -5px;
  }
}