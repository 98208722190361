.afterChildBirthTitle {
    font-size: 20px;
    font-weight: 500;
    color: var(--color-light-text);
    margin-block-start: 0em;
    margin-block-end: 0em;
}

.afterChildBirthDescription {
    font-size: 24px;
    font-weight: 600;
    color: var(--color-text);
    margin-block-start: 0em;
    margin-block-end: 0em;
}


@media screen and (max-width: 550px) {

    .afterChildBirthDescription {
        font-size: 20px;
        font-weight: 600;

    }

    .afterChildBirthTitle {
        font-size: 16px;
        font-weight: 500;
    }

}