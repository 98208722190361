.container {
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  width: 100%;
  max-width: 342px;
}

.wrapper {
  position: relative;
  display: flex;
  align-items: center;
  column-gap: 16px;
  padding: 0 24px;
  height: 72px;
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  font-feature-settings: 'pnum' on, 'lnum' on;
  border-radius: 16px;
  border: 1px solid var(--color-white);
  background-color: var(--color-white);
  transition: 0.3s ease-out;
}

.selected {
  border-color: var(--color-purple);
}

.check {
  z-index: 100;
  position: absolute;
  left: -10px;
  top: -15px;
  opacity: 0;
  transition: 0.3s ease-out;
}

.selected .check {
  opacity: 1;
}

@media screen and (max-width: 768px) {
  .container {
    row-gap: 20px;
    max-width: 270px;
  }
}

@media screen and (max-width: 738px) {
  .content {
    height: auto;
  }

  .container {
    overflow-x: scroll;
    flex-direction: row;
    width: 100%;
    max-width: 800px;
    column-gap: 0px;
    padding-top: 10px;
  }

  .container::-webkit-scrollbar {
    display: none;
  }

  .wrapper {
    flex-shrink: 0;
    width: 235px;
  }

  .wrapper:first-child,
  .wrapper:last-child {
    margin-left: 20px;
    margin-right: 20px;
  }
}
