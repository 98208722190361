.bloksContainer {
  display: flex;
  justify-content: center;
  column-gap: 40px;
  row-gap: 20px;
  flex-wrap: wrap;
  margin: 0 auto;
  padding: 20px 25px;
  border-radius: 16px;
  background-color: var(--color-white);
}

.bloksErrorContainer {
  border: 1px solid var(--color-red);
}

.block {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 10px;
}

.blockRow {
  display: flex;
  align-items: center;
  column-gap: 8px;
}

.blockLabel {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  font-feature-settings: 'pnum' on, 'lnum' on;
}

.error {
  margin: 0 auto;
  margin-top: 20px;
}

.bloksContainer, .error {
  width: 100%;
  max-width: 358px;
}

@media screen and (max-width: 320px) {
  .bloksContainer {
    column-gap: 20px;
    row-gap: 20px;
  }

  .error {
    margin-top: 10px;
  }
}
  