.container  {
  width: 100%;
  max-width: 1200px;
  padding: 0 20px;
  margin: 0 auto;
}

.header,
.main {
  background-color: var(--color-background);
}

.header {
  padding-top: 20px;
  padding-bottom: 5px;
}

.main {
  display: flex;
  flex-direction: column;
}

.iframe {
  width: 100%;
  border: none;
  padding: 0px;
  margin: 0px;
  scroll-behavior: smooth;
  overflow-x: hidden;
}


@media screen and (max-width: 768px) {
  .container {
    padding-left: 34px;
    padding-right: 34px;
  }
}

@media screen and (max-width: 320px) {
  .container {
    padding-left: 15px;
    padding-right: 15px;
  }
}
