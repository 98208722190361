.favoriteContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.iconContainer {
    width: fit-content;
    flex-direction: row;
    white-space: nowrap;
    padding-left: 30px;
    max-width: 550px;
    min-width: 288;
}