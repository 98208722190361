.container {
  cursor: pointer;
  margin-right: 10px;
}

.container:hover .icon {
  fill: var(--color-light-purple);
}

.icon {
  width: 30px;
  height: 30px;
  fill: var(--color-white);
  transition: 0.3s ease;
}

.scrolled {
  fill: var(--color-purple);
}

.container:hover .scrolled {
  fill: var(--color-purple-hover);
}

.big {
  width: 40px;
  height: 40px;
}

@media screen and (max-width: 320px) {
  .big {
    width: 30px;
    height: 30px;
  }
}