.container {
  cursor: pointer;
}

.icon {
  width: 180px;
  height: 50px;
}

.iconDark {
  width: 250px;
  height: 70px;
}

@media screen and (max-width: 440px) {
  .icon {
    width: 140px;
    height: 40px;
  }
}

@media screen and (max-width: 1152px) {
  .iconDark {
    width: 180px;
    height: 50px;
  }
}
