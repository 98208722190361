.container,
.containerEmpty {
  display: flex;
  padding: 16px 30px;
  justify-content: center;
  align-items: center;
  color: var(--purple, #9D8DF1);
  text-align: center;
  width: 100%;
  max-width: 165px;
  min-width: 135px;
  font-size: 14px;
  font-family: Raleway;
  font-weight: 700;
  line-height: 140%;
  text-transform: uppercase;
  border-radius: 16px;
  border: 1px solid var(--color-purple);
  background: var(--color-background);
  cursor: pointer;
  white-space: nowrap;

}

.containerEmpty {
  max-width: 183px;
}

.container:hover {
  background: linear-gradient(85.33deg, #E8E3FF 0%, #FFFFFF 100%);
}

@media screen and (max-width: 768px) {
  .container {
    width: 140px;
  }


}



@media screen and (max-width: 550px) {
  .container {
    width: 100%;
    height: 37px;
    border-radius: 12px;
    font-size: 12px;
  }

  .containerEmpty {
    width: 100%;
    height: 37px;
    border-radius: 12px;
    font-size: 12px;
    max-width: 165px;
  }
}