.descriptionContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 80px;
    margin-bottom: 100px;
}

.description {
    width: 100%;
    max-width: 790px;

}

.nextButton {
    display: flex;
    align-self: center;
    justify-content: center;
}

.lineBreak {
    white-space: pre-wrap;
}

.partOfTextContainer {
    height: 150px;
    position: relative;
    overflow: hidden;
}

.none {
    display: none;
}

.blurText {
    filter: blur(10px);
    height: 200px;
    overflow: hidden;
}

.buttonContainer {
    width: 231px;
    position: absolute;
    margin-bottom: 150px;
    z-index: 1;
    display: flex;
    align-self: center;
    justify-content: center;
}


.descriptionButton {
    width: 100%;
    display: flex;
    justify-content: center;
}

@media screen and (max-width: 768px) {

    .descriptionContainer {
        margin-top: 74px;
        margin-bottom: 74px;
        margin-top: 60px;
    }
}


@media screen and (max-width: 440px) {

    .descriptionContainer {
        margin-top: 50px;
        margin-bottom: 50px;
    }

}