.container {
  margin-top: 50px;
}

.titleContainer {
  display: flex;
  align-items: flex-end;
}

.wrongIcon {
  width: 24px;
  height: 24px;
  margin-right: 15px;
}

.title {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 26px;
  font-feature-settings: 'pnum' on, 'lnum' on;
}

.displayContainer {
  display: flex;
  align-items: center;
  margin: 20px 0;
  padding: 0 30px;
  width: 100%;
  height: 100px;
  border-radius: 20px;
  background-color: var(--color-white);
  border: none;
  color: var(--color-purple);
  cursor: pointer;
}

.displayContainer:focus {
  outline: none;
}

.number,
.unknown {
  font-family: 'Soyuz Grotesk';
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 53px;
}

.number {
  color: var(--color-purple);
}

.unknown {
  color: var(--color-red);
}

.separator {
  margin-top: 10px;
  margin-left: 20px;
  margin-right: 20px;
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  font-feature-settings: 'pnum' on, 'lnum' on;
}

.iconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  width: 34px;
  height: 70px;
  border-radius: 50px;
  background-color: var(--color-background);
}

.icon {
  width: 10px;
  height: 10px;
}

@media screen and (max-width: 960px) {
  .separator {
    margin-left: 10px;
    margin-right: 10px;
  }
}


@media screen and (max-width: 930px) {
  .displayContainer {
    height: 115px;
  }

  .number,
  .unknown {
    font-size: 64px;
    line-height: 70px;
  }

  .separator {
    margin-left: 20px;
    margin-right: 20px;
  }

  .iconContainer {
    min-width: 45px;
    height: 85px;
  }

  .icon {
    width: 15px;
    height: 15px;
  }
}

@media screen and (max-width: 570px) {
  .separator {
    margin-left: 10px;
    margin-right: 10px;
  }
}

@media screen and (max-width: 530px) {
  .number,
  .unknown {
    font-size: 50px;
    line-height: 56px;
  }
}


@media screen and (max-width: 460px) {
  .container {
    margin-top: 30px;
  }

  .wrongIcon {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }

  .title {
    font-size: 20px;
    line-height: 22px;
  }

  .displayContainer {
    margin: 15px 0;
    padding: 0 20px;
    height: 100px;
  }

  .number,
  .unknown {
    font-size: 36px;
    line-height: 40px;
  }

  .separator {
    margin-left: 5px;
    margin-right: 5px;
    font-size: 14px;
    line-height: 20px;
  }

  .iconContainer {
    min-width: 34px;
    height: 70px;
  }

  .icon {
    width: 10px;
    height: 10px;
  }
}
