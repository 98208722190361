.container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    align-items: center;
    padding-right: 30px;
}

.text {
    padding-left: 15px;
    margin-block-start: 0em;
    margin-block-end: 0em;
}


@media screen and (max-width: 768px) {
    .container {
        padding-right: 16px;

    }


}

@media screen and (max-width: 440px) {
    .container {
        padding-right: 16px;

    }

    .text {
        padding-left: 8px;
        font-weight: 600;
        font-size: 12px;
        color: var(--color-grey);
    }


}