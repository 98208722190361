.wrapper {
    max-height: 400px;
    overflow: auto;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-left: 15px;
}

.quantity,
.name,
.title,
.subtitle {
    margin-block-start: 0em;
    margin-block-end: 0em;
}

.quantity {
    font-weight: 400;
    font-size: 24px;
    color: var(--color-text);
    padding-bottom: 20px;
}

.quantity {
    margin-block-start: 0em;
    margin-block-end: 0em;
}

.name {
    font-size: 20px;
    font-weight: 500;
    color: var(--color-text);
    padding-bottom: 5px;
}

.title {
    font-size: 16px;
    font-weight: 500;
    color: var(--color-light-text);
    padding-bottom: 5px;
}

.subtitle {
    font-size: 14px;
    font-weight: 500;
    color: var(--color-grey);
}

.lesson {
    border: 1px solid;
    border-top: none;
    border-left: none;
    border-right: none;
    padding-top: 15px;
    padding-bottom: 15px;
    color: var(--color-lavander);
}

@media screen and (max-width: 768px) {
    .quantity {
        padding-bottom: 10px;
    }

    .wrapper {
        padding-left: 0px;
    }
}

@media screen and (max-width: 550px) {

    .name {
        font-size: 16px;
    }

    .quantity {
        font-size: 16px;
        font-weight: 400;
        padding-bottom: 5px;
    }

    .title {
        font-size: 14px;
    }

    .subtitle {
        font-size: 12px;
    }

    .lesson {

        padding-top: 10px;
        padding-bottom: 10px;
    }

}