.container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding-top: 130px;

}

.wrapper {
    padding-top: 30px;
    display: flex;
    flex-direction: row;
    position: relative;
}

.image {
    width: 30vw;
    min-height: 290px;
    max-height: 400px;
    border-radius: 30px;
    object-fit: cover;
    object-position: top;
}

.wrapperPostAndVideo {
    padding-top: 30px;
    padding-bottom: 100px;
    display: flex;
    flex-direction: column;
    position: relative;
}

.videoWrapper {
    width: 380px;
    max-width: 100%;
    border-radius: 30px;
}

.infoContainer {
    padding-left: 50px;
}

.clockContainer {
    display: flex;
    flex-direction: row;
    padding-top: 20px;
}

.time {
    padding-left: 15px;
    margin-block-start: 0em;
    margin-block-end: 0em;
    color: var(--color-grey);
    font-size: 16px;
}


.secondName {
    margin-block-start: 0em;
    margin-block-end: 0em;
}

.firstName {
    margin-block-start: 0em;
    margin-block-end: 0em;
}

h1 {
    margin-block-start: 0em;
    margin-block-end: 0em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}

.shareIcon {
    position: absolute;
    top: -98px;
    right: 0px;
}

.headerWrapper {
    display: flex;
    flex-direction: row;
    position: static;
}

.background {
    background-color: var(--color-background);
}



@media screen and (max-width: 860px) {
    .wrapper {
        padding-left: 0px;
        padding-right: 0px;
        display: flex;
        flex-direction: column;
    }

    .container {
        padding-left: 30px;
        padding-right: 30px;
        padding-top: 134px;
    }

    .infoContainer {
        padding-left: 0px;
    }

    .videoWrapper {
        width: 100%;
    }

    .image {
        width: 100%;
        margin-bottom: 30px;
    }

    .favoriteContainer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-self: center;
    }

    .clockContent {
        display: flex;
        justify-content: space-between;
    }
}


@media screen and (max-width: 550px) {

    .container {
        padding-top: 95px;
    }

    .container {
        padding-left: 15px;
        padding-right: 15px;

    }

    .videoWrapper {
        width: 100%;
        margin-bottom: 20px;
    }

    .wrapper {

        display: flex;
        flex-direction: column;
        position: relative;
    }

    .infoContainer {
        padding-left: 0px;
    }

    .favoriteContainer {
        display: flex;
        flex-direction: column;
        position: absolute;
        bottom: 260px;
        right: 0px;
    }

    .image {
        height: 200px;
        border-radius: 30px;
    }

    .header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
    }

    .headerContainer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-self: center;
    }

    .clockContent {
        display: flex;
        flex-direction: column;
        width: 100%;
    }
}