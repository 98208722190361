.title {
  margin-bottom: 10px;
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
  font-feature-settings: 'pnum' on, 'lnum' on;  
}

.data {
  font-family: 'Soyuz Grotesk';
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 53px;
  color: var(--color-purple);
}

@media screen and (max-width: 930px) {
  .data{
    font-size: 64px;
    line-height: 70px;
  }
}

@media screen and (max-width: 460px) {
  .title {
    margin-bottom: 5px;
    font-size: 16px;
    line-height: 21px;
  }

  .data {
    font-size: 36px;
    line-height: 40px;
  }
}
