.container {
  width: 131px;
  height: 52px;
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  text-transform: uppercase;
  font-feature-settings: 'pnum' on, 'lnum' on;
  color: var(--color-purple);
  background-color: var(--color-white);
  border-radius: 16px;
  transition: all 0.3s ease;
  box-shadow: 0px 8px 25px rgba(157, 141, 241, 0.4);
  cursor: pointer;
}

.container:hover {
  background-color: var(--color-light-purple);
}

.scrolled {
  color: var(--color-white);
  background-color: var(--color-purple);
}

.container:hover.scrolled {
  background-color: var(--color-purple-hover);
}

@media screen and (max-width: 768px) {
  .container {
    width: 340px;
    font-size: 24px;
  }
}

@media screen and (max-width: 550px) {
  .container {
    width: 100%;
    font-size: 12px;
  }
}