.container {
    width: 191px;
    height: 52px;
    border-radius: 16px;
    border: 1px solid #9D8DF1;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 700;
    color: var(--color-purple)
}

.container:hover {
    background: linear-gradient(to right, #E8E3FF, white);
    border: none;
}

.wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}