.videoWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    max-width: 100%;
    border-radius: 30px;
    margin-bottom: 30px;
    position: relative;
    padding: 0px;
    border-radius: 30px;
}

@media screen and (max-width: 768px) {
    .videoWrapper {
        width: 100%;
        display: flex;
        justify-content: center;
    }
}

@media screen and (max-width: 550px) {
    .videoWrapper {
        width: 100%;
        display: flex;
        justify-content: center;
    }
}



.videoImage {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 30px;
    object-fit: cover;
}


.startButton {
    background-color: transparent;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}