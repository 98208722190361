.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;

}

.lockedContent {
  position: relative;
  width: 90%;
  max-width: 750px;
  margin: 0 auto;
  padding: 40px 30px 0 50px;
  background-color: var(--color-white);
  border-radius: 40px;
  box-sizing: border-box;

}

.crossLock {
  position: absolute;
  top: -30px;
  right: 30px;
}

.lockWrapper {
  overflow: hidden;
}

.buttonsContainer {
  display: flex;
  justify-content: space-between;
}

.primaryButton {
  padding-top: 50px;
  padding-bottom: 40px;
}

.lockIcon {
  display: block;
  width: 20px;
  height: 20px;
}


.lockWrapper {
  position: relative;
  overflow: hidden;
}


.lock svg {
  position: absolute;
  right: 40px;
  bottom: -20px;
}

@media screen and (max-width: 768px) {
  .lockedContent {
    width: 90%;
    max-width: 500px;
  }

  .lock svg {
    position: absolute;
    right: 13px;
    bottom: -20px;
  }

}

@media screen and (max-width: 550px) {
  .lockedContent {
    width: 100%;
    min-width: 300px;
    width: 100%;
    padding: 30px 30px 13px 30px;
    margin-left: 15px;
    margin-right: 15px;
    border-radius: 20px;
  }

  .crossLock svg {
    height: 80px;
  }

  .crossLock {
    position: absolute;
    top: -30px;
    right: 15px;
  }

  .lock svg {
    position: absolute;
    right: 13px;
    bottom: 13px;
  }

  .lockWrapper {
    position: static;
    overflow: hidden;
  }

  .primaryButton {
    z-index: 10;
    padding-bottom: 20px;
  }
}