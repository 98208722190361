.container {
  display: flex;
  align-items: center;
  column-gap: 20px;
}

.icon {
  width: 40px;
  height: 40px;
  opacity: 0;
  transition: 0.5s ease-in-out;
}

.text {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  font-feature-settings: 'pnum' on, 'lnum' on;
  opacity: 0;
  transition: 0.5s ease-in-out;
}

.show {
  opacity: 1;
}

@media screen and (max-width: 320px) {
  .container {
    column-gap: 10px;
  }

  .icon {
    width: 30px;
    height: 30px;
  }

  .text {
    font-size: 12px;
    line-height: 17px;
  }
}
