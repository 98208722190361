.container {
  display: flex;
  justify-content: space-between;
  width: 475px;
  height: 220px;
  padding: 30px;
  border-radius: 30px;
  background-color: var(--color-white);
  box-shadow: 0px 10px 30px rgba(157, 141, 241, 0.2);
}

.wrapper {
  width: 100%;
  max-width: 221px;
  margin-left: 30px;
  margin-right: 10px;
}

.nameContainer {
  overflow: hidden;
  margin-bottom: 10px;
}

.name  {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 31px;
  font-feature-settings: 'pnum' on, 'lnum' on;
  white-space: pre-wrap;
  overflow-wrap: break-word;
}

.emailContainer {
  overflow: hidden;
}

.email {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  font-feature-settings: 'pnum' on, 'lnum' on;
  color: var( --color-light-text);
  white-space: pre-wrap;
  overflow-wrap: break-word;
}

@media screen and (max-width: 930px) {
  .container {
    width: 100%;
    height: auto;
  }

  .wrapper {
    margin-right: auto;
  }
}

@media screen and (max-width: 570px) {
  .container {
    flex-wrap: wrap;
    padding: 20px;
  }

  .wrapper {
    order: 2;
    margin-left: 0;
    margin-right: 40px;
    width: 100%;
  }

  .nameContainer {
    margin-top: 15px;
    margin-bottom: 0;
  }

  .name  {
    font-size: 18px;
    line-height: 25px;
  }

  .email {
    font-size: 14px;
    line-height: 20px;
  }
}

