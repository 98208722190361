.container {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  width: 100%;
}

.wrapper {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 30px;
  height: 90px;
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  font-feature-settings: 'pnum' on, 'lnum' on;
  border-radius: 20px;
  border: 1px solid var(--color-background);
  background-color: var(--color-background);
  transition: 0.3s ease-out;
  cursor: pointer;
}

.selected {
  border-color: var(--color-purple);
  background-color: var(--color-white);
}

.check {
  position: absolute;
  left: -10px;
  top: -15px;
  opacity: 0;
  transition: 0.3s ease-out;
}

.selected .check {
  opacity: 1;
}

.icon {
  width: 45px;
  height: auto;
  margin-right: 20px;
}

@media screen and (max-width: 430px) {
  .container {
    row-gap: 10px;
  }

  .wrapper {
    padding: 0 20px;
    height: 70px;
    font-size: 14px;
    line-height: 20px;
  }

  .icon {
    width: 35px;
    margin-right: 15px;
  }
}
