.container {
  display: none;
  position: absolute;
  top: 0;
  left: 34px;
  right: 34px;
  height: 540px;
  z-index: 1000;
  display: none;
  padding: 10px 20px 20px 20px;
  border-radius: 40px;
  background: linear-gradient(83.01deg, #7460DC 10.92%, #9D8DF1 89.08%);
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px 0 5px;
}

.closeIcone {
  cursor: pointer;
}

.main {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  row-gap: 30px;
  padding-top: 30px;

}

.footer {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 768px) {
  .container.show {
    display: flex;
    flex-direction: column;
  }

  .main {
    padding-top: 0px;

  }
}

@media screen and (max-width: 550px) {
  .container {
    left: 15px;
    right: 15px;
    border-radius: 30px;
  }

  .main {
    padding-top: 30px;

  }
}