.container,
.containerFull {
    display: flex;
    flex-direction: row;
    align-items: center;
    white-space: nowrap;
    border: 1px solid #000000;
    width: 100%;
    border-radius: 16px;
    justify-content: center;
    margin-left: 20px;
    cursor: pointer;
}

.containerFull {
    background-color: #000000;
    color: white;
}

.containerButtons {
    display: flex;
    flex-direction: row-reverse;
    margin-top: 30px;
}

.title {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 700;
    margin-left: 8px;
}

@media screen and (max-width: 550px) {
    .containerButtons {
        display: flex;
        flex-direction: column;
    }

    .container {
        margin-top: 10px;
        margin-left: 0px;
        margin-bottom: 10px;
    }

    .title {

        font-size: 12px;
        font-weight: 700;

    }

    .containerFull {
        background-color: #000000;
        color: white;
        margin-top: 10px;
        margin-left: 0px;
        margin-bottom: 10px;
    }


}