.messageContainer {
    display: flex;
    width: 100%;
    padding-bottom: 20px;
    position: relative;
    z-index: 1;
}


.quantity {
    margin-block-start: 0em;
    margin-block-end: 0em;
}

.quantity {
    font-size: 16px;
    font-weight: 500;
    color: var(--color-text);
}

@media screen and (max-width: 550px) {

    .quantity {
        font-size: 14px;
        line-height: 20px;
    }
}