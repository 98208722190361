.mzr_logo_block {
	display: flex;
	flex-direction: column;
	align-items: center;

}

.tag_mzr {
	width: 100%;
	max-width: 500px;
	margin-top: 10px;
	margin-bottom: 25px;
}

@media (max-width: 730px) {
	.tag_mzr {
		max-width: 320px;
	}

	.mzr_logo_block_flex_f {
		width: 20px;
		height: 20px;
	}
}





.mzr_logo_block_ft {
	font-weight: bold;
	font-size: 19.5px;
	color: #000;
}

.mzr_logo_block_flex {
	margin-bottom: 17px;
}

.mzr_logo_block_flex_f {
	width: 100px;
	height: 60px;
	margin-top: 8px;
	margin-right: 18px;
}

.mzr_logo_block_flex_s {
	width: 120px;
	margin-top: 8px;
}

@media (max-width: 460px) {
	.tag_mzr {
		width: 100%;
	}

	.mzr_logo_block_flex_f {
		width: 20px;
		height: 20px;
	}
}