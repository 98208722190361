.studyScreenWaitingContainer {
    display: flex;
    flex-direction: row;

    background-color: var(--color-white);
    border-radius: 40px;
    margin: 50px 0 0px;
    padding: 40px 50px;
    box-shadow: 0px 4px 25px rgba(157, 141, 241, 0.2);
}

.waitingContainerTextContainer {
    display: flex;
    width: 100%;
    justify-content: space-between;
    flex-direction: column;
}

.title {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.subtitle {
    font-size: 16px;
    line-height: 22px;
    font-weight: 500;
    max-width: 550px;
    width: 100%;
    color: var(--color-light-text);
    margin-block-start: 0em;
    margin-block-end: 0em;
}

.containerText {
    padding-bottom: 30px;
}

.icon {
    max-width: 142px;
    height: 142px;
}

.wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

@media screen and (max-width: 768px) {

    .studyScreenWaitingContainer {
        padding: 30px 40px;


    }

    .subtitle {

        padding-right: 0px;
    }

    .containerText {
        padding-top: 10px;
        padding-bottom: 30px;
    }
}



@media screen and (max-width: 550px) {
    .familyIconSmall {
        display: block;
    }

    .familyIcon {
        display: none;
    }

    .studyScreenWaitingContainer {
        width: 100%;
        padding-top: 20px;
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 20px;
        margin-top: 20px;
    }

    .subtitle {
        width: 100%;
        margin: 0px;
        font-size: 12px;
        font-weight: 500;
        padding: 0px;
    }

    .containerText {
        padding-top: 10px;
        padding-bottom: 15px;
    }

}


@media screen and (min-width: 441px) {

    .familyIconSmall {
        display: none;
    }

    .familyIcon {
        display: block;
    }
}


.familyIconSmall svg {
    width: 20vw;
    height: 19vw;
    max-width: 266px;
    min-width: 79px;
}

.familyIcon svg {
    width: 20vw;
    height: 19vw;
    max-width: 266px;
    min-width: 79px;
}