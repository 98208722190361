@media screen and (max-width: 768px) {
    .infoContainer {
        padding-left: 0px;
    }

    .clockWrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
}


@media screen and (max-width: 550px) {

    .infoContainer {
        padding-left: 0px;
    }

    .clockWrapper {
        display: flex;
        flex-direction: column;
    }
}

.clockContainer {
    display: flex;
    flex-direction: row;
    padding-top: 20px;
}