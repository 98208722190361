.container {
  display: flex;
  padding: 16px 30px;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  width: 100%;
  max-width: 165px;
  min-width: 135px;
  text-align: center;
  font-size: 14px;
  font-family: Raleway;
  font-weight: 700;
  line-height: 140%;
  text-transform: uppercase;
  cursor: pointer;
  color: var(--color-red);
  border-radius: 16px;
  white-space: nowrap;
}

.container:hover {
  background-color: var(--color-white);
}

.bigContainer {
  width: 198px;
}

@media screen and (max-width: 768px) {}



@media screen and (max-width: 550px) {
  .container {
    width: 100%;
    height: 37px;
    border-radius: 12px;
    font-size: 12px;
  }
}