@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100&family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@font-face {
  font-family: "Soyuz Grotesk";
  /* src: local("Soyuz Grotesk"), url("../fonts/SoyuzGrotesk-Bold.otf") format("opentype") tech(color-COLRv1), url("../fonts/SoyuzGrotesk-Bold.otf") format("opentype"), url("../fonts/SoyuzGrotesk-Bold.woff") format("woff"); */
  src: local("Soyuz Grotesk"), url("../fonts/SoyuzGrotesk-Bold.otf") format("opentype");
}

@font-face {
  font-family: 'Fontatica4F';
  src: url('../fonts/Fontatica4F.eot');
  src: local('Fontatica 4F'), local('Fontatica4F'),
    url('../fonts/Fontatica4F.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Fontatica4F.woff2') format('woff2'),
    url('../fonts/Fontatica4F.woff') format('woff'),
    url('../fonts/Fontatica4F.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Fontatica4F';
  src: url('../fonts/Fontatica4F-Italic.eot');
  src: local('fonts/Fontatica 4F Italic'), local('fonts/Fontatica4F-Italic'),
    url('../fonts/Fontatica4F-Italic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Fontatica4F-Italic.woff2') format('woff2'),
    url('../fonts/Fontatica4F-Italic.woff') format('woff'),
    url('../fonts/Fontatica4F-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto Light';
  src: local('fonts/Roboto-Light'), local('fonts/Roboto-Light'),
    url('../fonts/Roboto-Light.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto Thin';
  src: local('fonts/Roboto-Thin'), local('fonts/Roboto-Thin'),
    url('../fonts/Roboto-Thin.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto Regular';
  src: local('fonts/Roboto-Regular'), local('fonts/Roboto-Regular'),
    url('../fonts/Roboto-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}


/* //// */

* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
  /* transition: 0.5s; */
}

/* TODO check is it nessecary here */
/* a:hover{
  color: var(--color-purple);
} */

li {
  list-style: none;
}

ul {
  margin: 0;
}

input {
  margin: 0;
  padding: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
       appearance: none;
}

input[type=password]::-ms-reveal,
input[type=password]::-ms-clear {
  display: none;
}

button {
  text-decoration: none;
  border: none;
}

/* //// */

html {
  height: 100%;
  font-size: 62.5%;
  /* 16px x 62.5 = 10px = 1rem */
  color: var(--color-text);
  background-color: var(--color-background);
  scroll-behavior: smooth;
}

body {
  min-width: 320px;
  height: 100%;
  margin: 0;
  padding: 0;
  font-size: 1.6rem;
  font-weight: 500;
  font-family: 'Raleway', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.container {
  width: 100%;
  max-width: 1130px;
  padding: 0 2em;
  margin: 0 auto;
}


/* /////// */

.hidden {
  overflow: hidden;
}

.none {
  display: none;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flex-column-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.flex-row-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

button {
  cursor: pointer;
}
