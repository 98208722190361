.section {
  background-color: var(--color-background);
  padding-bottom: 30px;
}

.container {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
  max-width: 1200px;
}

.article {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.wapper {
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  width: 100%;
  max-width: 500px;
}

.descriptionContainer {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 34px;
  font-feature-settings: 'pnum' on, 'lnum' on;
  text-align: start;
}

.iconAdvice {
  width: auto;
  height: 740px;
}

.iconInterests {
  width: auto;
  height: 580px;
}

.iconStatus  {
  width: auto;
  height: 580px;
}

/* ///// */

.blockStatuses  {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 210px;
  width: 395px;
  padding-left: 30px;
}

.containerStatus {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 365px;
  height: 60px;
}

.checkIcon {
  width: 30px;
  height: 30px;  
}

.wrapperStatus {
  display: flex;
  align-items: center;
  width: 320px;
  height: 60px;
  padding: 0 24px;
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  font-feature-settings: 'pnum' on, 'lnum' on;
  border-radius: 16px;
  border: 1px solid var(--color-white);
  background-color: var(--color-white);
}

.containerStatusIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  margin-right: 16px;
}

.statusIcon {
  height: 40px;
  width: auto;
}


@media screen and (max-width: 1250px) {
  .container {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media screen and (max-width: 965px) {
  .blockStatuses  {
    height: 180px;
    width: 325px;
    padding-left: 30px;
  }

  .containerStatus {
    width: 295px;
    height: 50px;
  }

  .checkIcon {
    width: 25px;
    height: 25px;  
  }

  .wrapperStatus {
    width: 255px;
    height: 50px;
    padding: 0 15px;
  }

  .containerStatusIcon {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }
  
  .statusIcon {
    height: 30px;
  }
}

@media screen and (max-width: 940px) {
  .iconAdvice {
    width: 325px;
    height: auto;
  }

  .iconInterests {
    width: 365px;
    height: auto;
  }

  .iconStatus {
    width: 375px;
    height: auto;
  }

  .wapper {
    max-width: 325px;
    row-gap: 20px;
  }

  .descriptionContainer {
    font-size: 19px;
    line-height: 28px;
  }

  .blockStatuses  {
    height: 180px;
    width: 100%;
    max-width: 290px;
  }

  .wrapperStatus {
    width: 255px;
    height: 50px;
    padding: 0 15px;
  }
}

@media screen and (max-width: 767px) {
  .container {
    padding-left: 34px;
    padding-right: 34px;
  }

  .article {
    flex-direction: column;
  }

  .wapper {
    order: 1;
    row-gap: 10px;
    max-width: 768px;
  }

  .descriptionContainer {
    padding-bottom: 20px;
  }

  .iconInterests,
  .iconStatus {
    order: 2;
    width: 100%;
    height: auto;
  }

  .iconAdvice {
    order: 2;
    width: auto;
    height: 370px;
  }

  .blockStatuses  {
    padding-left: 0px;
    margin-bottom: 30px;
  }

}

@media screen and (max-width: 480px) {
  .container {
    padding-left: 15px;
    padding-right: 15px;
  }

  .descriptionContainer {
    font-size: 16px;
    line-height: 22px;
  }
}
