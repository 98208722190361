.text {
  font-family: 'Soyuz Grotesk';
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 58px;
  letter-spacing: 0.03em;
  text-align: start;
}

.highlight {
  color: var(--color-white);
  background-color: var(--color-purple);
}
  
.center {
  text-align: center;
}
  
@media screen and (max-width: 1080px) {
  .text {
    font-size: 36px;
    line-height: 43px;
  }
}

@media screen and (max-width: 480px) {
  .text {
    font-size: 28px;
    line-height: 34px;
  }
}
