.container {
  width: 34px;
  height: 70px;
  border-radius: 50px;
}

.icon {
  width: 10px;
  height: 10px;
}

@media screen and (max-width: 768px) {
  .container {
    width: 45px;
    height: 85px;
  }

  .icon {
    width: 15px;
    height: 15px;
  }
}

@media screen and (max-width: 320px) {
  .container {
    width: 34px;
    height: 70px;
  }

  .icon {
    width: 10px;
    height: 10px;
  }
}
