.block {
  background-color: var(--color-white);
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 15px;
  padding-bottom: 15px;
  border-radius: 15px;
  margin-top: 30px;
  display: flex;
  flex-direction: row;
}

.moreButton {
  color: var(--color-purple);
  text-decoration: underline;
  font-size: 16px;
  font-weight: 500;
}

.description {
  font-size: 16px;
  font-weight: 500;
}

@media screen and (max-width: 768px) {}

@media screen and (max-width: 440px) {

  .block {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 0px;
    padding-bottom: 0px;
    margin-top: 20px;
  }

  .description {
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    padding: 0px;
  }

  .moreButton {
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
  }

}