.container {
  display: flex;
  align-items: center;
  column-gap: 20px;
  width: 100%;
  max-width: 380px;
  height: 124px;
  padding-left: 30px;
  padding-right: 30px;
  border-radius: 21px;
  background-color: var(--color-white);
}

.icon {
  width: 60px;
  height: 60px;
}

.title {
  font-family: 'Soyuz Grotesk';
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 40px;
  letter-spacing: 0.03em;
  color: var(--color-purple);
}

.text {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  font-feature-settings: 'pnum' on, 'lnum' on;
}

@media screen and (max-width: 1230px) {
  .container:first-child {
    margin-right: auto;
  }

  .container:last-child {
    margin-left: auto;
  }
}


@media screen and (max-width: 370px) {
  .container {
    height: 95px;
    column-gap: 10px;
    padding-left: 5px;
    padding-right: 5px;
  }

  .icon {
    width: 50px;
    height: 50px;
  }

  .title {
    font-size: 30px;
    line-height: 34px;
  }
}

@media screen and (max-width: 768px) {
  .title {
    font-size: 24px;
    line-height: 26px;
  }
}
