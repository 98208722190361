.inputWrapper {
    margin-top: 10px;
}

.inputContainer {
    background-color: var(--color-background);
}

@media screen and (max-width: 550px) {

    .inputWrapper {
        margin-top: 0px;
    }
}