.container {
    background-color: var(--color-white);
    color: var(--color-text);
    border-radius: 20px;
    padding: 20px;
    min-width: 290px;
    max-width: 320px;
    margin-bottom: 20px;
    display: flex;
    flex: 1 1 0;
    width: 0;
    outline: 0px solid var(--color-purple);
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    margin-right: 10px;
    margin-left: 10px;
    transition: 0.1s;
}

.container:hover {
    outline: 2px solid var(--color-purple);
}

.container::before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    transition: 0.1s;
}

.container:hover::before {
    width: calc(100% + 1px);
    height: calc(100% + 1px);
    transition: 0.1s;
}

.imageContainer {
    border-radius: 15px;
    width: 100%;
    height: 100%;
    max-width: 280px;
    max-height: 200px;
    object-fit: cover;
    object-position: top;
    margin-bottom: 20px;
    min-height: 'auto';
}

.titleContainer {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
}

.title {

    font-size: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    font-weight: 500;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-block-start: 0em;
    margin-block-end: 0em;
}

.subTitle {
    font-size: 20px;
    font-weight: 500;
    line-height: 26px;
    margin-block-start: 0em;
    margin-block-end: 0em;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.containerSvg {
    top: 30px;
    position: absolute;

}

@media screen and (max-width: 880px) {
    .container {
        width: 100%;
    }

    .imageContainer {
        width: 100%
    }
}

@media screen and (max-width: 440px) {
    .container {
        display: flex;
        flex-direction: row;
        width: 100%;
        padding: 10px;
    }

    .imageContainer {
        width: 80px;
        height: 80px;
        margin: 0;
    }

    .description {
        display: flex;
        flex-direction: column;
        align-content: start;
        font-size: 12px;
        width: 100%;
        padding-left: 15px;
        max-width: 240px;
        min-width: 175px;
        height: 100%;
        justify-content: space-between;
    }

    .title {
        padding-top: 0px;
        padding-bottom: 10px;
        font-size: 14px;
        font-weight: 600;
    }

    .subTitle {
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        margin-block-start: 0em;
        margin-block-end: 0em;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        text-align: start;
        align-self: self-start;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
}

.labels {
    margin-top: 20px;
}