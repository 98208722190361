.container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 50px;
    margin-bottom: 30px;
    width: 100%;
    justify-content: center;
}

.filler {
    flex: 1 1 0px;
    max-width: 340px;
    min-width: 300px
}

@media screen and (max-width: 550px) {
    .container {
        display: flex;
        flex-direction: column;
        margin-top: 30px;
        align-items: center;
    }
}

@media screen and (max-width: 387px) {
    .container {
        display: flex;
        flex-direction: column;
        margin-top: 15px;
        align-items: center;

    }

}