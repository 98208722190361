.blocksWrapper {
  padding-left: 100px;
  padding-right: 100px;
  padding-bottom: 70px;
}

@media screen and (max-width: 880px) {
  .blocksWrapper {
    padding-left: 0px;
    padding-right: 0px;
  }
}