.container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 50px;
}

.weekContainer {
    width: 660px;
    display: flex;
    justify-content: space-between;
}

.title {
    font-size: 36px;
    font-family: 'Raleway';
    display: flex;
    align-self: flex-start;
    font-weight: 700;
    margin-block-start: 0em;
    margin-block-end: 0em;
    padding-bottom: 30px;
}

@media screen and (max-width: 1260px) {
    .container {
        margin-bottom: 0px;
    }
}


@media screen and (max-width: 768px) {
    .weekContainer {
        width: 100%;
        max-width: 720px;
        margin-left: -10px;
        margin-right: -10px;
    }

    .container {
        margin-bottom: 30px;
    }
}


@media screen and (max-width: 550px) {
    .title {
        font-size: 24px;
        font-weight: 700;
        padding-bottom: 20px;
    }

    .container {
        margin-bottom: 0px;
    }

    .weekContainer {
        width: 100%;
        max-width: 720px;
        margin-left: -5px;
        margin-right: -5px;
    }
}