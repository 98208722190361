.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
  width: 100%;
}

.controlsContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  width: 100%;
}

@media screen and (max-width: 860px) {
  .container {
    display: flex;
    flex-direction: column;
  }

  .controlsContainer {

    width: auto;
  }
}