.chips {
    display: inline-block;
    margin-top: 25px;
    background-color: var(--color-white);
    padding: 14px 20px;
    border-radius: 10px;
    margin-right: 15px;
    white-space: nowrap;
}

.chips:hover {
    background-color: var(--color-light-purple);
}

.withoutText {
    display: inline-block;
    margin-top: 25px;
    background-color: var(--color-white);
    padding: 14px 20px;
    border-radius: 10px;
    margin-right: 15px;
    white-space: nowrap;
}


.chipsWrapper {
    display: flex;
    flex-direction: row;
    text-align: center;
    position: relative;
}

.chipsTitle,
.chipsTitleUpper {
    color: var(--color-text);
    padding-left: 10px;
    line-height: 20px;
    font-size: 14px;
    font-weight: 700;
    color: var(--color-black);
    font-size: 14px;
    font-weight: 500;
    margin-block-start: 0em;
    margin-block-end: 0em;
}

.chipsTitleUpper {
    text-transform: uppercase;
}

@media screen and (max-width: 550px) {
    .withoutText,
    .chips  {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 8px 16px;
    }

}