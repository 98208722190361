.wrapper_certificate {
  position: absolute;
  z-index: 100;
  top: 900px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 24px;
  background-color: blueviolet;
}

.name {
  font-size: 50px;
  font-weight: bold;
}