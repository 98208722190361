.container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 54px;
  height: 54px;
  border-radius: 12px;
  background-color: var(--color-light-purple);
  overflow: hidden;
}

.bigContainer {
  width: 120px;
  height: 160px;
  border-radius: 20px;
}

.image {
  width: auto;
  height: 100%;
}

.container>.imageUser {
  width: 30px;
  height: 30px;
}

.bigContainer>.imageUser {
  width: 70px;
  height: 86px;
}

@media screen and (max-width: 768px) {
  .container {
    width: 48px;
    height: 48px;
  }

  .container>.imageUser {
    width: 27px;
    height: 27px;
  }

  .bigContainer {
    min-width: 160px;
    height: 210px;
  }

  .bigContainer>.imageUser {
    width: 92px;
    height: 113px;
  }
}

@media screen and (max-width: 440px) {
  .container {
    width: 36px;
    height: 36px;
  }

  .container>.imageUser {
    width: 20px;
    height: 20px;
  }

  .bigContainer {
    min-width: 120px;
    height: 160px;
  }

  .bigContainer>.imageUser {
    width: 70px;
    height: 86px;
  }
}