:root {
  --color-black: #000000;
  --color-white: #FFFFFF;
  --color-purple: #9D8DF1;
  --color-purple-hover: #8b76f0;
  --color-light-purple: #E8E3FF;
  --color-text: #3C3C3B;
  --color-light-text: #5F5F5F;
  --color-grey: #999999;
  --color-light-grey: #C2C2C2;
  --color-middle-grey: #9D9D9D;
  --color-background: #F3F3F5;
  --color-green: #6DB81C;
  --color-red: #E52A1D;
  --color-solitude: #DEE9F7;
  --color-light-blue: #E2ECFD;
  --color-lavander: #E8E3FF;
}
