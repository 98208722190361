.container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 150px;
  min-width: 109px;
  height: 52px;
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  text-transform: uppercase;
  font-feature-settings: 'pnum' on, 'lnum' on;
  color: var(--color-white);
  border-radius: 16px;
  color: var(--color-white);
  background-color: var(--color-purple);
  box-shadow: 0px 8px 25px rgba(157, 141, 241, 0.4);
  cursor: pointer;
}

.container:hover {
  background: linear-gradient(83.01deg, #7460DC 10.92%, #9D8DF1 89.08%);
}

.lightContainer {
  color: var(--color-purple);
  background-color: var(--color-white);
}

.lightContainer:hover {
  background: linear-gradient(85.33deg, #E8E3FF 0%, #FFFFFF 100%);
}


@media screen and (max-width: 768px) {}



@media screen and (max-width: 550px) {
  .container {
    width: 100%;
    max-width: 109px;
    height: 37px;
    border-radius: 12px;
    font-size: 12px;
  }
}