.pregnancyTitle,
.pregnancySubtitle {
    margin-block-start: 0em;
    margin-block-end: 0em;
}

.pregnancyTitle {
    font-weight: 600;
    font-size: 24px;
    line-height: 31px;
}

.pregnancySubtitle {
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    color: var(--color-light-text)
}

.pregnancyDate {
    width: 285px;
}

.trimesterContainer {
    width: 285px;
}

@media screen and (max-width: 550px) {
    .pregnancyTitle {
        font-weight: 600;
        font-size: 20px;
        line-height: 22px;
    }

    .pregnancySubtitle {
        font-size: 16px;
    }

    .pregnancyDate {
        margin-bottom: 20px;
    }
}