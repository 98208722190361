.studyContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.studyTitleContainer {
    width: 100%;
    display: flex;
    justify-content: center;
}

.studyTitle {
    font-size: 48px;
    font-weight: 700;
    text-align: center;
    font-family: 'Soyuz Grotesk';
    padding-bottom: 50px;
    line-height: 57px;
    margin-block-start: 0em;
    margin-block-end: 0em;
}


@media screen and (max-width: 768px) {
    .studyTitleContainer {
        margin-left: 11px;
    }

}

@media screen and (max-width: 550px) {
    .studyTitle {
        font-size: 28px;
        padding-top: 0px;
        padding-bottom: 20px;

    }

    .studyTitleContainer {
        margin-left: 0;
        display: flex;
    }
}