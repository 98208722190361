.text {
  font-family: 'Soyuz Grotesk';
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 58px;
  letter-spacing: 0.03em;
  text-align: start;
}

.text_light {
  font-family: 'Soyuz Grotesk';
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 58px;
  letter-spacing: 0.03em;
  text-align: start;
  color: var(--color-white);
}

.highlight {
  color: var(--color-white);
  background-color: var(--color-purple);
}

.highlight_light {
  color: var(--color-purple);
  background-color: var(--color-white);
}

.center {
  text-align: center;
}

@media screen and (max-width: 480px) {
  .text, .text_light {
    font-size: 38px;
    line-height: 46px;
  }
}

@media screen and (max-width: 320px) {
  .text, .text_light {
    font-size: 36px;
    line-height: 43px;
  }
}
