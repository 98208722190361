.react-datepicker__portal {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}

.react-datepicker {
  padding: 30px 35px 30px 40px;
  width: 420px;
  height: auto;
  background-color: var(--color-white);
  box-shadow: 0px 4px 25px rgba(157, 141, 241, 0.2);
  border-radius: 30px;
  border: none;
}

.react-datepicker__triangle {
  display: none;
}

.react-datepicker__header {
  padding: 0;
  border-bottom: none;
  background-color: var(--color-white);
}

.react-datepicker__nav-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 340px;
}

.react-datepicker__nav-container--button {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 34px;
  height: 70px;
  border-radius: 50px;
  transition: all 0.4 ease-in-out;
}

.react-datepicker__nav-container--icon {
  width: 10px;
  height: 10px;
  fill: var(--color-text);
}

.react-datepicker__nav-container--button:hover {
  background-color: var(--color-purple);
}

.react-datepicker__nav-container--button:hover .react-datepicker__nav-container--icon {
  fill: var(--color-white);
}

.react-datepicker__current-month {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 340px;
  height: 70px;
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  text-transform: uppercase;
  font-feature-settings: 'pnum' on, 'lnum' on;
  color: var(--color-text);
}

.react-datepicker__header__dropdown {
  display: none;
}

.react-datepicker__day-names {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0;
  width: 345px;
}

.react-datepicker__day-name {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45px !important;
  height: 20px;
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  text-transform: uppercase;
  font-feature-settings: 'pnum' on, 'lnum' on;
  color: var(--color-grey);
}

.react-datepicker__month {
  width: 345px;
  margin: 0;
}

.react-datepicker__week {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.react-datepicker__day {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  width: 45px !important;
  height: 45px;
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  text-transform: uppercase;
  font-feature-settings: 'pnum' on, 'lnum' on;
  color: var(--color-text);
}

.react-datepicker__day--selected {
  color: var(--color-purple);
  border-radius: 10px;
  border: 1px solid var(--color-purple);
  background-color: transparent;
}

.react-datepicker__day--keyboard-selected {
  color: var(--color-white);
  border-radius: 10px;
  border: 1px solid var(--color-purple);
  background-color: var(--color-purple);
}

.react-datepicker__day:hover,
.react-datepicker__day--selected:hover {
  color: var(--color-text);
  border-radius: 10px;
  border: 1px solid var(--color-background);
  background-color: var(--color-background);;
}

.react-datepicker__day--outside-month {
  color: var(--color-grey);
}

.react-datepicker__day--excluded {}


@media screen and (max-width: 530px) {
  .react-datepicker {
    padding: 20px;
    width: 280px;
    height: auto;
  }

  .react-datepicker__nav-container {
    width: 240px;
  }

  .react-datepicker__current-month {
    font-size: 12px !important;
    line-height: 17px;
  }

  .react-datepicker__month {
    width: 240px;
  }

  .react-datepicker__day-names {
    margin: 10px 0;
    width: 240px !important;
  }

  .react-datepicker__day-name {
    width: 30px !important;
    height: 17px;
    font-size: 12px;
    line-height: 17px;
  }

  .react-datepicker__day {
    width: 30px !important;
    height: 30px;
    font-size: 12px;
    line-height: 17px;
  }
}
