.header {
  z-index: 2250;
  position: fixed;
  top: 20px;
  width: 100%;
}

.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 40px;
  padding-right: 40px;
  height: 80px;
  border-radius: 80px;
  transition: 0.4s ease-in-out;
}

.scrolled {
  background-color: var(--color-white);
  box-shadow: -8px 0px 10px rgba(157, 141, 241, 0.1), 8px 8px 10px rgba(157, 141, 241, 0.1);
}

.wrapperSection {
  display: flex;
  align-items: center;
  column-gap: 20px;
}

.visibleContainer {}

.infoContainer {
  display: flex;
  align-items: center;
  column-gap: 20px;
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  font-feature-settings: 'pnum' on, 'lnum' on;
}

.userFirstName {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  font-feature-settings: 'pnum' on, 'lnum' on;
  color: var(--color-white);
  text-transform: capitalize;
}

.userFirstNameScrolled {
  color: var(--color-text);
}

@media screen and (max-width: 1200px) {
  .container {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media screen and (max-width: 768px) {
  .header {
    z-index: 2250;
    top: 34px;
  }

  .container {
    padding-left: 34px;
    padding-right: 34px;
  }

  .wrapper {
    padding-left: 30px;
    padding-right: 30px;
  }

  .visibleContainer {
    display: none;
  }
}

@media screen and (max-width: 730px) {
  .visibleContainer {
    display: none;
  }
}


@media screen and (max-width: 550px) {
  .header {
    top: 15px;
  }

  .container {
    padding-left: 15px;
    padding-right: 15px;
  }

  .wrapper {
    padding-left: 20px;
    padding-right: 20px;
    height: 60px;
    border-radius: 60px;
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 10;
}