.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  margin: 0 auto;
  width: 100%;
  max-width: 1000px;
}

.wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  width: 100%;
  max-width: 320px;
  height: 85px;
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  font-feature-settings: 'pnum' on, 'lnum' on;
  border-radius: 20px;
  border: 2px solid var(--color-white);
  background-color: var(--color-white);
  transition: 0.3s ease-out;
}

.selected {
  border-color: var(--color-purple);
}

.check {
  position: absolute;
  left: -10px;
  top: -12px;
  opacity: 0;
  transition: 0.3s ease-out;
}

.selected .check {
  opacity: 1;
}

.icon {
  width: auto;
  height: 48px;
}

@media screen and (max-width: 768px) {
  .wrapper {
    max-width: 320px;
  }
}

@media screen and (max-width: 320px) {
  .container {
    gap: 10px;
  }

  .wrapper {
    height: 62px;
    font-size: 16px;
    line-height: 21px;
  }

  .icon {
    height: 32px;
  }
}
