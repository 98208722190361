.auth-header {
  padding-top: 50px;
  padding-bottom: 55px;
}

.auth-header,
.auth-main {
  background-color: var(--color-background);
}

.auth-container {
  width: 100%;
  max-width: 1200px;
  padding: 0 20px;
  margin: 0 auto;

}

.auth-header-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 10px;
  row-gap: 20px;
  min-height: 95px;
}

.auth-main {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.auth-subtitle-container {
  margin-top: 50px;
}

.auth-description {
  margin-top: 20px;
  width: 100%;
  max-width: 500px;
}

.auth-section-title {
  margin-top: 50px;
  margin-bottom: 30px;
}

.auth-inputs-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 360px;
  row-gap: 20px;
  margin-top: 50px;
  margin-bottom: 70px;
}

.auth-row-buttons-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 360px;
  column-gap: 27px;
}

.auth-column-buttons-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 360px;
  row-gap: 20px;
  margin-bottom: 100px;
}

.auth-OR,
.auth-text {
  margin: 25px auto;
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  text-transform: uppercase;
  font-feature-settings: 'pnum' on, 'lnum' on;
  color: var(--color-light-grey);
}

.auth-text {
  padding-right: 20px;
}

.auth-next-container,
.auth-create-account-container {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 30px;
  margin-top: 70px;
  margin-bottom: 100px;
  width: 100%;
}

.auth-next-container {
  max-width: 510px;
}

.auth-create-account-container {
  max-width: 215px;
}

.auth-section-container {
  margin: 0 auto;
  width: 100%;
  max-width: 510px;
  text-align: center;
}

#onboarding_animation {
  width: 100%;
  max-width: 350px;
}

.auth-checkList {
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  margin-top: 30px;
  margin-bottom: 100px;
  width: 100%;
  max-width: 320px;
}

.check-success-icon {
  margin-top: 100px;
  margin-bottom: 30px;
}

.auth-buttons-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  row-gap: 10px;
  column-gap: 30px;
  width: 100%;
  max-width: 500px;
  margin-top: 70px;
  margin-bottom: 236px;
}

.button-start_education,
.button-back_home {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  width: 235px;
  height: 52px;
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  text-transform: uppercase;
  font-feature-settings: 'pnum' on, 'lnum' on;
  border-radius: 16px;
  box-shadow: 0px 8px 25px rgba(157, 141, 241, 0.4);
  cursor: pointer;
}

.button-start_education {
  background-color: var(--color-purple);
  color: var(--color-white);
}

.button-start_education:hover {
  background: linear-gradient(83.01deg, #7460DC 10.92%, #9D8DF1 89.08%);
}


.button-back_home {
  background-color: var(--color-background);
  color: var(--color-purple);
  border: 1px solid var(--color-purple);
}

/* .button-back_home:hover {
  background: linear-gradient(85.33deg, #E8E3FF 0%, #FFFFFF 100%);
} */

.button-start_education_text {
  color: var(--color-white);
}

@media screen and (max-width: 768px) {
  .auth-container {
    padding-left: 34px;
    padding-right: 34px;
  }

  .auth-header {
    padding-top: 34px;
    padding-bottom: 30px;
  }

  .auth-subtitle-container {
    margin-top: 30px;
  }

  .auth-inputs-container {
    margin-bottom: 50px;
  }

  .auth-column-buttons-container {
    margin-bottom: 83px;
  }

  .auth-next-container {
    margin-top: 50px;
    margin-bottom: 70px;
  }

  .auth-buttons-container {
    margin-top: 50px;
    margin-bottom: 298px;
  }

  .auth-buttons-container {
    flex-direction: column;
    row-gap: 10px;
  }
}

@media screen and (max-width: 564px) {
  .auth-header-container {
    flex-wrap: wrap;
  }

  .auth-buttons-container {
    flex-direction: column;
    row-gap: 10px;
  }

  .button-start_education,
  .button-back_home {
    width: 290px;
  }
}

@media screen and (max-width: 448px) {
  .auth-next-container {
    flex-direction: column;
    row-gap: 10px;
  }
}

@media screen and (max-width: 425px) {
  .check-success-icon {
    margin-top: 50px;
    width: 120px;
    height: 120px;
  }
}

@media screen and (max-width: 320px) {
  .auth-container {
    padding-left: 15px;
    padding-right: 15px;
  }

  .auth-header {
    padding-top: 15px;
    padding-bottom: 20px;
  }

  .auth-header-container {
    min-height: 60px;
  }

  .auth-section-title {
    margin-top: 30px;
    margin-bottom: 20px;
  }

  .auth-inputs-container {
    row-gap: 10px;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .auth-row-buttons-container {
    column-gap: 10px;
  }

  .auth-column-buttons-container {
    row-gap: 10px;
    margin-bottom: 50px;
  }

  .auth-OR,
  .auth-text {
    font-size: 12px;
    line-height: 17px;
  }

  .auth-text {
    padding-right: 10px;
  }

  .auth-next-container {
    margin-top: 30px;
    margin-bottom: 50px;
  }

  .auth-create-account-container {
    max-width: 176px;
  }

  .auth-buttons-container {
    margin-top: 30px;
    margin-bottom: 154px;
  }

  .button-start_education,
  .button-back_home {
    font-size: 12px;
    line-height: 17px;
  }
}