.background {
  background-color: var(--color-background);
}

.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding-top: 130px;
  padding-bottom: 318px;
}

.links {
  color: black;
  text-decoration: none;
}

@media screen and (max-width: 1200px) {

  .container {
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 134px;
    padding-bottom: 190px;
  }

}

@media screen and (max-width: 768px) {

  .container {
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 214px;
    padding-top: 95px;
  }

}

@media screen and (max-width: 440px) {

  .container {
    padding-top: 95px;
    padding-left: 15px;
    padding-right: 15px;
  }

}