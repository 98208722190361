.searchContainer {
    position: relative;
    max-width: 1000px;
    min-width: 290px;
    width: nowrap;
    padding-bottom: 70px;
}

.searchWrapper {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    width: 100%;
    background-color: var(--color-white);
    border-radius: 20px;
    padding-left: 20px;
    z-index: 2240;
}

.clicked {
    border: 2px solid var(--color-purple);
    border-radius: 20px;
}

.search {
    display: flex;
    height: 70px;
    width: 100%;
}

.studyScreenSearch {
    border-width: 0px;
    height: 100%;
    width: 100%;
    border-radius: 20px;
    outline: none;
    position: relative;
}

.searchIconContainer {
    padding-right: 20px;
    padding-left: 10px;
    display: flex;
    align-items: center;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 2260;
}

.commonButton {
    color: var(--color-purple);
    font-size: 16px;
    font-weight: 500;
    padding-right: 30px;
    background-color: transparent;
}

@media screen and (max-width: 768px) {
    .searchContainer {
        padding-bottom: 50px;
    }

    .search {
        height: 64px;
    }
}

.modal {
    background-color: var(--color-white);
    position: absolute;
    top: 70px;
    left: 0px;
    width: 100%;
}

@media screen and (max-width: 550px) {

    .searchWrapper {
        border-radius: 16px;

    }

    .searchIconContainer {
        padding-right: 10px;
        padding-left: 0px;
    }

    .commonButton {
        padding-right: 20px;
    }

    .searchContainer {
        padding-bottom: 50px;
    }

    .search {
        height: 47px;
    }
}