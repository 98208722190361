.section {
  background-color: var(--color-background);
}

.container {
  display: flex;
  flex-direction: column;
  margin: 0 auto 100px auto;
  padding: 50px 60px;
  width: 100%;
  max-width: 1000px;
  border-radius: 30px;
  background: linear-gradient(83.01deg, #7460DC 10.92%, #9D8DF1 89.08%);
}

.wrapper {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.linksContainer {
  display: flex;
  align-items: center;
  column-gap: 30px;
}

@media screen and (max-width: 1250px) {
  .section {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media screen and (max-width: 768px) {
  .section {
    padding-left: 34px;
    padding-right: 34px;
  }

  .container {
    padding: 40px 50px;
    margin-bottom: 70px;
  }

  .icon {
    width: 104px;
    height: 80px;
  }
}

@media screen and (max-width: 690px) {
  .container {
    row-gap: 40px;
    margin-bottom: 50px;
    background: url("../../../../assets/icons/home/logo_back_light_purple.png") no-repeat right bottom, linear-gradient(83.01deg, #7460DC 10.92%, #9D8DF1 89.08%);
  }

  .icon {
    display: none;
  }
}

@media screen and (max-width: 560px) {
  .container {
    row-gap: 30px;
    align-items: center;
    padding: 30px 20px;
  }

  .linksContainer {
    flex-direction: column;
    row-gap: 10px;
  }
}

@media screen and (max-width: 480px) {
  .section {
    padding-left: 15px;
    padding-right: 15px;
  }
}
