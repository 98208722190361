.section {
  position: relative;
  padding-bottom: 100px;
  background-color: var(--color-background);
}

.anchor {
  position: absolute;
  top: -130px;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  width: 100%;
  max-width: 1200px;
}

.wrapper {
  display: flex;
  align-items: center;
  column-gap: 100px;
  margin-top: 70px;
}

.descriptionContainer {
  width: 100%;
  max-width: 485px;
}

.name {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 43px;
  font-feature-settings: 'pnum' on, 'lnum' on;
}

.description {
  margin-top: 10px;
  margin-bottom: 30px;
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  font-feature-settings: 'pnum' on, 'lnum' on;
  color: var(-color-light-text);
}

.list {
  list-style: none;
  padding-left: 25px;
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  font-feature-settings: 'pnum' on, 'lnum' on;
}

.list li {
  display: list-item;
  list-style-type: disc;
  list-style-position: outside;
}

.instButton {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 15px;
  margin-top: 30px;
  width: 100%;
  max-width: 218px;
  height: 62px;
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  text-transform: uppercase;
  font-feature-settings: 'pnum' on, 'lnum' on;
  color: var(--color-white);
  background: #9D8DF1;
  box-shadow: 0px 8px 25px rgba(157, 141, 241, 0.4);
  border-radius: 16px;
}

.icon {
  fill: var(--color-white);
}

.photo {
  align-self: flex-start;
  width: 415px;
  height: 550px;
}


@media screen and (max-width: 1200px) {
  .container {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media screen and (max-width: 860px) {
  .section {
    padding-bottom: 92px;
  }
  
  .container {
    padding-left: 34px;
    padding-right: 34px;
  }

  .wrapper {
    margin-top: 50px;
    column-gap: 50px;
  }

  .photo {
    width: 325px;
    height: 430px;
  }

  .descriptionContainer {
    max-width: 325px;
  }

}

@media screen and (max-width: 750px) {
  .section {
    padding-bottom: 50px;
  }

  .container {
    padding-left: 15px;
    padding-right: 15px;
  }

  .wrapper {
    margin-top: 30px;
    flex-direction: column;
    row-gap: 20px;
  }

  .photo {
    align-self: flex-start;
    width: 250px;
    height: 330px;
  }

  .descriptionContainer {
    max-width: 750px;
  }

  .name {
    font-size: 28px;
    line-height: 34px;
  }

  .description {
    margin-top: 5px;
    margin-bottom: 10px;
  }

  .instButton {
    max-width: 170px;
    height: 60px;
    font-size: 12px;
    line-height: 17px;
  }
}


@media screen and (max-width: 480px) {
}
