.container {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 320px;
  padding: 20px 30px;
  border-radius: 16px;
  border: 2px solid transparent;
  background-color: var(--color-white);
  transition: all 0.2s ease-in;
}

.container:hover {
  border: 2px solid var(--color-purple);
}

.iconLeft {
  width: 30px;
  height: 30px;
}

.title {
  margin-left: 15px;
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  font-feature-settings: 'pnum' on, 'lnum' on;
}

.redTitle {
  color: var(--color-red);
}

@media screen and (max-width: 930px) {
  .container {
    max-width: 340px;
  }
}

@media screen and (max-width: 767px) {
  .container {
    max-width: 290px;
  }
}

@media screen and (max-width: 675px) {
  .container {
    max-width: 675px;
  }
}

@media screen and (max-width: 675px) {
  .container {
    padding: 15px 20px;
  }

  .iconLeft {
    width: 25px;
    height: 25px;
  }

  .title {
    font-size: 14px;
    line-height: 20px;
  }
}
